import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Alert from '../../Alert';
import constants from 'constants/config';
// import groupIcon from "../../../../images";
import groupIcon from '../../../images/groupIcon.png';
import QuestionAnswerNewOverlay from '../../SAAS/QuestionAnswerNewOverlay';
import AsyncDropDown from '../../AsyncDropDown';
import axios from 'axios';
import constant from '../../../constants';
import Select from 'react-select';
import Tooltip from '../Tooltip/Tooltip';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';
import AdditionalCommentsField from '../AdditionalCommentsField/AdditionalCommentsField';

export const isNewMasterDataRequested = elem => {
  const isNewAnswerRequested = elem.answers.some(
    answer => answer.isNew || answer.istranslatedNew
  );
  return elem.isNew || elem.istranslatedNew || isNewAnswerRequested;
};

function QnAFieldv2({
  elem,
  index,
  isValid,
  errors,
  campaignType,
  updateQuestion,
  qualifyingQuestionHandler,
  qualifyingQuestionCount,
  removeAccordion,
  maximumQualifingQuestionsErrorIndices,
  isEdited,
  expand,
  handleAccordionExpandCollapse,
  isMasterData,
  isOpenGuidelineModel,
  campaignConfig
}) {
  const handleSelectData = (selectedOption, index) => {
    document
      .getElementById(`ReasonForMasterdata_${index}`)
      .classList.add('d-none');
    document
      .getElementById(`reason_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`reason_${index}`)
        .classList.remove('cw-error-focus');

    elem.reasonForCreation = selectedOption.value;
    updateQuestion({ ...elem }, index);
  };

  const getNewQueCallback = (newQue, index, isNewQue) => {
    // here new que comming from que overlay
    document
      .getElementById(`questionError_${index}`)
      .classList.contains('cw-hidden') ||
      document
        .getElementById(`questionError_${index}`)
        .classList.add('cw-hidden');

    document.getElementById(`questionTranslationError_${index}`) &&
      document
        .getElementById(`questionTranslationError_${index}`)
        .classList.add('cw-hidden');

    document.getElementById(`questionLabelError_${index}`) &&
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

    if (isNewQue) {
      elem = {
        ...elem,
        ...newQue,
        questionLabel: newQue.translatedLabel
          ? newQue.translatedLabel
          : newQue.englishLabel,
        istranslatedNew: newQue.translatedLabel ? true : false
      };
    } else {
      elem = {
        ...elem,
        englishLabel: newQue.text,
        id: newQue.id,
        questionLabel: newQue.translatedText
          ? newQue.translatedText
          : newQue.text,
        translatedLabel: newQue.translatedText ? newQue.translatedText : '',
        isNew: false,
        translatedId: newQue.translatedId ? newQue.translatedId : '',
        istranslatedNew: newQue.istranslatedNew
          ? newQue.translatedId
            ? false
            : true
          : false,
        reasonForCreation: ''
      };
    }
    if (elem.translatedLabel) {
      validateTranslatedLabel(elem.translatedLabel);
    }
    if (elem.questionLabel) {
      validateQuestionLabel(elem.questionLabel);
    }
    updateQuestion({ ...elem }, index);
  };

  const _getQuestions = (input, callback) => {
    let options = [];
    axios
      .get(constant.serviceUrls.SERVICE_URL.QUESTIONS + '/?text=' + input)
      .then(response => {
        options = response.data.map(question => ({
          value: question.id,
          label: question.text
        }));
        callback(options);
      });
  };

  const _getAnswers = async (input, callback) => {
    let options = [];
    if (!input) {
      return Promise.resolve(options);
    }
    const response = await axios.get(
      constant.serviceUrls.SERVICE_URL.ANSWERS + '/?text=' + input
    );
    options = response.data.map(question => ({
      value: question.id,
      label: question.text
    }));
    return Promise.resolve(options);
  };

  const _getTranslations = async (type, id, qIndex, ansIndex) => {
    let { campaignLanguageCode } = questionProps;
    let url =
      type === 'answer'
        ? constant.serviceUrls.SERVICE_URL.GET_TRANSLATED_ANSWERS
        : constant.serviceUrls.SERVICE_URL.GET_TRANSLATED_QUESTIONS;
    let response = await axios.get(
      url + '?id=' + id + '&lang=' + campaignLanguageCode
    );
    let el;
    if (type === 'question') {
      if (response && response.data.length >= 0) {
        let data = response.data[0];
        el = 'questionTranslatedLabel_' + qIndex;

        elem.translatedId = data && data.id ? data.id : '';
        elem.translatedLabel = data && data.text ? data.text : '';
        elem.questionLabel = data && data.text ? data.text : '';

        updateQuestion({ ...elem }, index);
        if (document.getElementById(`questionLabel_${qIndex}`)) {
          document.getElementById(`questionLabel_${qIndex}`).value =
            data && data.text ? data.text : '';
        }
        if (document.getElementById(el)) {
          document.getElementById(el).value =
            data && data.text ? data.text : '';
        }
        if (document.getElementById(el)) {
          document.getElementById(el).value = elem.translatedLabel
            ? elem.translatedLabel
            : '';
        }
      }
    }
    if (type === 'answer') {
      el = 'answerTranslatedLabel_' + qIndex + '_' + ansIndex;
      if (response && response.data.length) {
        let data = response.data[0];
        let answerUpdated = elem.answers;
        answerUpdated.splice(ansIndex, 1, {
          value: id,
          label: answerUpdated[ansIndex]['label'],
          translatedId: data && data.id ? data.id : '',
          translatedLabel: data && data.text ? data.text : '',
          answerLabel: data && data.text ? data.text : '',
          englishLabel: answerUpdated[ansIndex]['label']
        });
        if (document.getElementById(`answerLabel_${qIndex}_${ansIndex}`)) {
          document.getElementById(`answerLabel_${qIndex}_${ansIndex}`).value =
            data && data.text ? data.text : '';
        }
        //answerTranslationError_2_0
        // if (document.getElementById(`answerLabel_${qIndex}_${ansIndex}`) &&
        //   document.getElementById(`answerLabel_${qIndex}_${ansIndex}`).value.trim().length === 0 ){
        //     document.getElementById(`answerLabelError_${qIndex}_${ansIndex}`) &&
        //   document.getElementById(`answerLabelError_${qIndex}_${ansIndex}`).classList.add('cw-hidden');
        // }
        if (document.getElementById(el)) {
          document.getElementById(el).value =
            data && data.text ? data.text : '';
        }

        updateQuestion({ ...elem }, index);
      } else {
        if (document.getElementById(`answerLabel_${qIndex}_${ansIndex}`)) {
          document.getElementById(`answerLabel_${qIndex}_${ansIndex}`).value =
            '';
        }
        if (document.getElementById(el)) {
          document.getElementById(el).value = '';
        }
      }
    }
  };

  let mandatoryQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    elem.mandatoryToggle = isChecked;
    updateQuestion({ ...elem }, index);
    // setMandatory(event.target.checked);
  };

  let _handleQuestion = async (selectedOption, actionMeta, index) => {
    document.getElementById(`questionDropdown_${index}`) &&
      document
        .getElementById(`questionDropdown_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionDropdown`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionDropdown`)
        .classList.remove('cw-error-focus');

    document.getElementById(`questionTranslatedLabel_${index}`) &&
      document
        .getElementById(`questionTranslatedLabel_${index}`)
        .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionTranslatedLabel_${index}`)
        .classList.remove('cw-error-focus');

    if (document.getElementById(`questionTranslationError_${index}`)) {
      document
        .getElementById(`questionTranslationError_${index}`)
        .classList.add('cw-hidden');
    }

    if (document.getElementById(`questionLabelError_${index}`)) {
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

      document
        .getElementById(`questionLabel_${index}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`questionLabel_${index}`)
          .classList.remove('cw-error-focus');
    }

    elem.isNew =
      actionMeta && actionMeta.action === 'create-option'
        ? selectedOption.__isNew__
        : false;
    elem.id =
      selectedOption && selectedOption.value && Number(selectedOption.value)
        ? selectedOption.value
        : '';
    elem.englishLabel = selectedOption && selectedOption.label;
    elem.istranslatedNew = false;
    elem.reasonForCreation = '';
    if (questionProps.campaignLanguageCode === 'EN') {
      elem.questionLabel = selectedOption && selectedOption.label;
      if (document.getElementById(`questionLabel_${index}`)) {
        document.getElementById(`questionLabel_${index}`).value =
          selectedOption && selectedOption.label;
      }
    } else {
      await _getTranslations('question', selectedOption.value, index, null);
      let languageName = questionProps && questionProps.campaignLanguage;

      if (
        document.getElementById(`questionTranslatedLabel_${index}`) &&
        document.getElementById(`questionTranslatedLabel_${index}`).value.trim()
          .length === 0
      ) {
        document.getElementById(
          `questionTranslatedLabel_${index}`
        ).placeholder =
          'No translation found. Please enter in ' +
          languageName +
          ' translation.';
      }
    }

    updateQuestion({ ...elem }, index);
  };

  let handleMandatoryMessage = (e, index, elem) => {
    let enteredText = e.target.value;
    if (enteredText.trim().length) {
      document.getElementById(`errorMessage_${elem.value}_${index}`) &&
        document
          .getElementById(`errorMessage_${elem.value}_${index}`)
          .classList.add('cw-hidden');

      document
        .getElementById(`errorMessage_${index}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`errorMessage_${index}`)
          .classList.remove('cw-error-focus');
    } else {
      document.getElementById(`errorMessage_${elem.value}_${index}`) &&
        document
          .getElementById(`errorMessage_${elem.value}_${index}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`errorMessage_${index}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`errorMessage_${index}`)
          .classList.add('cw-error-focus');
    }
    elem.mandatoryMessage = elem.mandatoryToggle ? enteredText : '';
    updateQuestion({ ...elem }, index);
  };

  let handleToolTip = (e, index) => {
    let enteredText = e.target.value;
    elem.toolTip = enteredText;
    updateQuestion({ ...elem }, index);
  };

  let onTranslationLabelChange = (e, index) => {
    let enteredText = e.target.value;
    validateTranslatedLabel(enteredText);

    elem.translatedLabel = enteredText;
    elem.questionLabel = enteredText;
    if (enteredText.trim().length > 0) {
      elem.istranslatedNew = true;
      elem.translatedId = '';
    } else {
      elem.istranslatedNew = false;
    }
    if (document.getElementById(`questionLabel_${index}`)) {
      document.getElementById(`questionLabel_${index}`).value = enteredText;
    }
    updateQuestion({ ...elem }, index);
  };

  const onAdditionalCommentsChange = e => {
    elem.additionalComments = e.target.value;
    document.getElementById(`additionalComments_description_${index}`) &&
    document
      .getElementById(`additionalComments_description_${index}`)
      .classList.add('d-none');
  document.getElementById(`additionalComments_${index}`) &&
    document
      .getElementById(`additionalComments_${index}`)
      .classList.add('cw-error-focus');
    updateQuestion({ ...elem }, index);
  };

  const validateTranslatedLabel = enteredText => {
    if (enteredText.trim().length > 0) {
      document.getElementById(`questionTranslationError_${index}`) &&
        document
          .getElementById(`questionTranslationError_${index}`)
          .classList.add('cw-hidden');

      document.getElementById(`questionLabelError_${index}`) &&
        document
          .getElementById(`questionLabelError_${index}`)
          .classList.add('cw-hidden');

      document.getElementById(`questionLabel_${index}`) &&
        document
          .getElementById(`questionLabel_${index}`)
          .classList.contains('cw-error-focus') &&
        document
          .getElementById(`questionLabel_${index}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`questionTranslatedLabel_${index}`) &&
        document
          .getElementById(`questionTranslatedLabel_${index}`)
          .classList.contains('cw-error-focus') &&
        document
          .getElementById(`questionTranslatedLabel_${index}`)
          .classList.remove('cw-error-focus');
    } else {
      document.getElementById(`questionTranslationError_${index}`) &&
        document
          .getElementById(`questionTranslationError_${index}`)
          .classList.remove('cw-hidden');

      document.getElementById(`questionLabelError_${index}`) &&
        document
          .getElementById(`questionLabelError_${index}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`questionLabel_${index}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`questionLabel_${index}`)
          .classList.add('cw-error-focus');

      document
        .getElementById(`questionTranslatedLabel_${index}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`questionTranslatedLabel_${index}`)
          .classList.add('cw-error-focus');
    }
  };

  const validateQuestionLabel = enteredText => {
    if (enteredText.trim().length > 0) {
      document.getElementById(`questionLabelError_${index}`) &&
        document
          .getElementById(`questionLabelError_${index}`)
          .classList.add('cw-hidden');

      document.getElementById(`questionLabel_${index}`) &&
        document
          .getElementById(`questionLabel_${index}`)
          .classList.contains('cw-error-focus') &&
        document
          .getElementById(`questionLabel_${index}`)
          .classList.remove('cw-error-focus');

      document
        .getElementById(`questionError_${index}`)
        .classList.contains('cw-hidden') ||
        document
          .getElementById(`questionError_${index}`)
          .classList.add('cw-hidden');
    } else {
      document.getElementById(`questionLabelError_${index}`) &&
        document
          .getElementById(`questionLabelError_${index}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`questionLabel_${index}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`questionLabel_${index}`)
          .classList.add('cw-error-focus');
    }
  };

  let onLabelChange = (e, index, elem) => {
    let enteredText = e.target.value;
    validateQuestionLabel(enteredText);

    let { campaignLanguageCode } = questionProps;
    elem.questionLabel = enteredText;
    updateQuestion({ ...elem }, index);
  };

  const questionTypesSelect = [
    {
      label: 'Data enrichment',
      value: 'Data enrichment',
      id: 1
    },
    {
      label: 'Operational',
      value: 'Operational',
      id: 2
    }
  ];

  let handleTranslationAnswerLabel = (e, index, indexValue) => {
    let enteredText = e.target.value;
    elem.answers[indexValue].translatedLabel = enteredText;
    elem.answers[indexValue].answerLabel = enteredText;
    if (enteredText.trim().length > 0) {
      elem.answers[indexValue].istranslatedNew = true;
      elem.answers[indexValue].translatedId = '';
    } else {
      elem.answers[indexValue].istranslatedNew = false;
    }
    if (document.getElementById(`answerLabel_${index}_${indexValue}`)) {
      document.getElementById(
        `answerLabel_${index}_${indexValue}`
      ).value = enteredText;
    }
    if (enteredText.trim().length > 0) {
      document.getElementById(
        `answerTranslationError_${index}_${indexValue}`
      ) &&
        document
          .getElementById(`answerTranslationError_${index}_${indexValue}`)
          .classList.add('cw-hidden');

      document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
        document
          .getElementById(`answerLabelError_${index}_${indexValue}`)
          .classList.add('cw-hidden');

      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');

      document
        .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');
    } else {
      document.getElementById(
        `answerTranslationError_${index}_${indexValue}`
      ) &&
        document
          .getElementById(`answerTranslationError_${index}_${indexValue}`)
          .classList.remove('cw-hidden');

      document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
        document
          .getElementById(`answerLabelError_${index}_${indexValue}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`answerLabel_${index}_${indexValue}`)
          .classList.add('cw-error-focus');

      document
        .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .classList.add('cw-error-focus');
    }
    updateQuestion({ ...elem }, index);
  };

  const getNewAnsCallback = (newAns, index, isNewAns) => {
    if (isNewAns) {
      let selectedAns = elem.answers;
      let newObj = {
        label: newAns.englishLabelAns,
        value: '',
        englishLabel: newAns.englishLabelAns,
        translatedLabel: newAns.translatedLabelAns
          ? newAns.translatedLabelAns
          : '',
        answerLabel: newAns.translatedLabelAns
          ? newAns.translatedLabelAns
          : newAns.englishLabelAns,
        translatedId: '',
        isNew: isNewAns,
        istranslatedNew: newAns.translatedLabelAns ? true : false
      };
      selectedAns.push(newObj);
      elem.answers = selectedAns;
      if (selectedAns && selectedAns.length >= 2) {
        document
          .getElementById(`answerError_${index}`)
          .classList.contains('cw-hidden') ||
          document
            .getElementById(`answerError_${index}`)
            .classList.add('cw-hidden');
      }
      updateQuestion({ ...elem }, index);
    } else {
      var selectedAns =
        elem.answers && elem.answers.length > 0 ? elem.answers : [];

      for (let ele of newAns) {
        let newObje = {
          label: ele.text,
          value: ele.id,
          englishLabel: ele.text,
          translatedLabel: ele.translatedText ? ele.translatedText : '',
          answerLabel: ele.translatedText ? ele.translatedText : ele.text,
          translatedId: ele.translatedId ? ele.translatedId : '',
          isNew: false,
          istranslatedNew: ele.istranslatedNew
            ? ele.translatedId
              ? false
              : true
            : false
        };
        if (selectedAns && selectedAns.length) {
          let findindex = selectedAns.findIndex(e => e.value === newObje.value);
          if (findindex > -1) {
            selectedAns[findindex] = newObje;
          } else {
            selectedAns.push(newObje);
          }
        } else {
          selectedAns.push(newObje);
        }
      }

      elem.answers = selectedAns;
      if (selectedAns && selectedAns.length >= 2) {
        document
          .getElementById(`answerError_${index}`)
          .classList.contains('cw-hidden') ||
          document
            .getElementById(`answerError_${index}`)
            .classList.add('cw-hidden');
      }
      updateQuestion({ ...elem }, index);
      elem.answers &&
        elem.answers.length &&
        elem.answers.forEach((elm, indexValue) => {
          if (
            document.getElementById(
              `answerTranslationError_${index}_${indexValue}`
            ) &&
            elm.translatedLabel &&
            elm.translatedLabel.trim().length > 0
          ) {
            document
              .getElementById(`answerTranslationError_${index}_${indexValue}`)
              .classList.add('cw-hidden');

            if (
              document
                .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
                .classList.contains('cw-error-focus')
            ) {
              document
                .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
                .classList.remove('cw-error-focus');
            }
          }

          if (
            document.getElementById(
              `answerLabelError_${index}_${indexValue}`
            ) &&
            elm.answerLabel &&
            elm.answerLabel.trim().length > 0
          ) {
            document
              .getElementById(`answerLabelError_${index}_${indexValue}`)
              .classList.add('cw-hidden');

            if (
              document
                .getElementById(`answerLabel_${index}_${indexValue}`)
                .classList.contains('cw-error-focus')
            ) {
              document
                .getElementById(`answerLabel_${index}_${indexValue}`)
                .classList.remove('cw-error-focus');
            }
          }
        });
    }
  };

  let handleAnswerLabel = (e, index, indexValue) => {
    let enteredText = e.target.value;
    elem.answers[indexValue].answerLabel = enteredText;

    if (enteredText.trim().length) {
      document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
        document
          .getElementById(`answerLabelError_${index}_${indexValue}`)
          .classList.add('cw-hidden');

      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');
    } else {
      document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
        document
          .getElementById(`answerLabelError_${index}_${indexValue}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`answerLabel_${index}_${indexValue}`)
          .classList.add('cw-error-focus');
    }
    updateQuestion({ ...elem }, index);
  };

  let _handleAnswer = async (selectedOption, actionMeta, indexValue, index) => {
    if (
      document.getElementById(`answerTranslationError_${index}_${indexValue}`)
    ) {
      document
        .getElementById(`answerTranslationError_${index}_${indexValue}`)
        .classList.add('cw-hidden');
    }

    if (document.getElementById(`answerLabelError_${index}_${indexValue}`)) {
      document
        .getElementById(`answerLabelError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');
    }

    let answerQ = elem.answers;
    answerQ.splice(selectedOption.index, 1, {
      value:
        actionMeta && actionMeta.action === 'create-option'
          ? ''
          : selectedOption.value
          ? selectedOption.value
          : '',
      label: selectedOption.label,
      isNew: actionMeta && actionMeta.action === 'create-option',
      englishLabel: selectedOption.label,
      // answerQ[indexValue] && answerQ[indexValue]["englishLabel"]
      //   ? answerQ[indexValue]["englishLabel"]
      //   : selectedOption.label,
      translatedId: '',
      translatedLabel: ''
    });
    if (document.getElementById(`answerDropdownError_${index}_${indexValue}`)) {
      document
        .getElementById(`answerDropdownError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

      document
        .getElementById(`answerDropdown_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerDropdown_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`answerTranslatedLabel_${index}_${indexValue}`) &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');
    }

    if (questionProps.campaignLanguageCode === 'EN') {
      answerQ[indexValue].answerLabel = selectedOption && selectedOption.label;
      if (document.getElementById(`answerLabel_${index}_${indexValue}`)) {
        document.getElementById(`answerLabel_${index}_${indexValue}`).value =
          selectedOption && selectedOption.label;
      }
    } else {
      await _getTranslations('answer', selectedOption.value, index, indexValue);
      let languageName = questionProps && questionProps.campaignLanguage;
      if (
        document.getElementById(
          `answerTranslatedLabel_${index}_${indexValue}`
        ) &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .value.trim().length === 0
      ) {
        document.getElementById(
          `answerTranslatedLabel_${index}_${indexValue}`
        ).placeholder =
          'No translation found. Please enter in ' +
          languageName +
          ' translation.';
      }
    }
    updateQuestion({ ...elem }, index);
  };

  let _deleteAnswer = async (index, indexValue) => {
    elem && elem.answers && elem.answers.splice(indexValue, 1);
    updateQuestion({ ...elem }, index);

    elem.answers &&
      elem.answers.length &&
      elem.answers.forEach((elm, indexValue) => {
        if (
          document.getElementById(
            `answerTranslationError_${index}_${indexValue}`
          ) &&
          elm.translatedLabel &&
          elm.translatedLabel.trim().length > 0
        ) {
          document
            .getElementById(`answerTranslationError_${index}_${indexValue}`)
            .classList.add('cw-hidden');

          if (
            document
              .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
              .classList.contains('cw-error-focus')
          ) {
            document
              .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
              .classList.remove('cw-error-focus');
          }
        }

        if (
          document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
          elm.answerLabel &&
          elm.answerLabel.trim().length > 0
        ) {
          document
            .getElementById(`answerLabelError_${index}_${indexValue}`)
            .classList.add('cw-hidden');

          if (
            document
              .getElementById(`answerLabel_${index}_${indexValue}`)
              .classList.contains('cw-error-focus')
          ) {
            document
              .getElementById(`answerLabel_${index}_${indexValue}`)
              .classList.remove('cw-error-focus');
          }
        }
      });
  };

  const questionProps = useSelector(state => ({
    questions: state.questionsAnswers.questions,
    addedQuestions: state.questionsAnswers.addedQuestions,
    prevAddedQuestions: state.questionsAnswers.prevAddedQuestions,
    answers: state.questionsAnswers.answers,
    selectedQuestionType: state.questionsAnswers.selectedQuestionType,
    fetchingQuestions: state.questionsAnswers.fetchingQuestions,
    fetchingAnswers: state.questionsAnswers.fetchingAnswers,
    questionsLoaded: state.questionsAnswers.questionsLoaded,
    answersLoaded: state.questionsAnswers.answersLoaded,
    dataSubmittedToApi: state.questionsAnswers.dataSubmittedToApi,
    campaignDetailsLoaded: state.questionsAnswers.campaignDetailsLoaded,
    approverEditCampaign: state.app.approverEditCampaign,
    questionanswerssaved: state.questionsAnswers.questionanswerssaved,
    campaignLanguage: state.questionsAnswers.campaignLanguage,
    campaignLanguageCode: state.questionsAnswers.campaignLanguageCode,
    editCampaign: state.overview.editCampaign,
    editCampaignDetails: state.app.editCampaignDetails,
    isCampaignEdited: state.questionsAnswers.isCampaignEdited,
    websiteType: state.questionsAnswers.websiteType,
    isLanguageEnglish: state.questionsAnswers.isLanguageEnglish,
    campaignType: state.questionsAnswers.campaignType,
    autogenerate: state.campaignDetails.autogenerate,
    type: state.campaignDetails.type,
    incentiveType: state.campaignDetails.incentiveType,
    campaign: state.questionsAnswers.campaign,
    fields: state.questionsAnswers.fields,
    formMetaData: state.saasFormAuthoring,
    campaignDetails: state.campaignDetails
  }));

  let answerIsNew =
    elem.answers && elem.answers.map((elem, indexValue) => elem.isNew);
  let answerHasNewFlag =
    answerIsNew && answerIsNew.filter(elem => elem).length > 0;
  let answerTranslatedIsNew =
    elem.answers &&
    elem.answers.map((elem, indexValue) => elem.istranslatedNew);
  let answerHasNewTranslatedFlag =
    answerTranslatedIsNew &&
    answerTranslatedIsNew.filter(elem => elem).length > 0;
  if (elem.dataType === 'questionAnswerPair') {
    return (
      <div
        id={`accordion_${elem.value}_${index + 1}`}
        className="accordion saas-form-field-accordion mb-20 saas-form-field-accordion-qna field"
        key={`question_${index + 1}`}
      >
        {/* <ReactTooltip
                place="right"
                type="info"
                multiline={true}
                className="cw-tooltip"
              /> */}
        <ErrorIcon isValid={isValid} />
        <div
          className="card"
          style={{
            border: 0,
            overflow: 'visible',
            width: '100%'
          }}
        >
          <div className="card-header">
            <span
              className="saas-accordion-heading cw-color--primary"
              style={{ fontWeight: '700' }}
            >
              {elem.questionLabel
                ? elem.questionLabel.length < 70
                  ? elem.questionLabel
                  : elem.questionLabel.substring(0, 70) + '...'
                : elem.label}
            </span>
            <span
              type="button"
              className={`saas-accordion-arrow ${index}`}
              onClick={() => handleAccordionExpandCollapse(index)}
            >
              <i
                className={
                  expand === index
                    ? 'fas fa-chevron-down transformIcon-180'
                    : 'fas fa-chevron-down'
                }
              ></i>
            </span>
            <span
              className={'saas-accordion-close'}
              onClick={() => {
                removeAccordion(index);
              }}
            ></span>
            {elem.isNew ||
            elem.istranslatedNew ||
            answerHasNewTranslatedFlag ||
            answerHasNewFlag ? (
              <>
                <ReactTooltip
                  id="saas_new_master_tooltip"
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip saas_new_master_tooltip"
                />
                <span
                  className={'saas_new_master_logo'}
                  style={{
                    display: 'block',
                    color: '#E9A40A'
                  }}
                  data-tip="New master data request"
                  data-for="saas_new_master_tooltip"
                >
                  <img src={groupIcon} alt={groupIcon} width="25" height="25" />
                </span>
              </>
            ) : null}
          </div>

          <div className={expand === index ? 'collapse show' : 'collapse'}>
            {elem.value === 'freeText' && (
              <>
                <a
                  href="#"
                  onClick={isOpenGuidelineModel}
                  // style={{ fontWeight: 'bold' }}
                  className="float-right mt-10 mb-10"
                >
                  Q&A guidelines
                </a>{' '}
                <div className="custon-alert-msg mt-20">
                  <br />
                  <Alert
                    alertType="warningMessageWithIcon"
                    showComponent={true}
                    textMessage={constants.FREE_TEXT_WARNING}
                  />
                </div>
              </>
            )}
            {elem.value === 'freeTextArea' && (
              <>
                <a
                  href="#"
                  onClick={isOpenGuidelineModel}
                  // style={{ fontWeight: 'bold' }}
                  className="float-right mt-10 mb-10"
                >
                  Q&A guidelines
                </a>
                <div className="custon-alert-msg mt-40 mb-10">
                  <Alert
                    alertType="saasInfoMessageWithIcon"
                    // showComponent={true}
                    textMessage={constants.FREE_TEXT_AREA_BLUE_WARNING}
                  />
                  <Alert
                    alertType="warningMessageWithIcon"
                    showComponent={true}
                    textMessage={constants.FREE_TEXT_AREA_WARNING}
                  />
                </div>
              </>
            )}
            <>
              <div
                className="form-group form-group-field-width-qna"
                key={`questionDropdown_${index + 1}`}
              >
                <div className="cw-question-answer-dotted-line mt-15 cw-question-answer-v2">
                  {elem.value !== 'freeTextArea' && elem.value !== 'freeText' && (
                    <a
                      href="#"
                      onClick={isOpenGuidelineModel}
                      // style={{ fontWeight: 'bold' }}
                      className="float-right mb-10"
                    >
                      Q&A guidelines
                    </a>
                  )}
                  <div className="cw-heading-qna mb-10">
                    <span
                      className="float-left  fa fa-circle mt-10"
                      style={{ marginTop: '5px' }}
                    ></span>
                    <strong style={{ marginLeft: '15px', color: '#000' }}>
                      Question
                    </strong>
                  </div>
                  {!isEdited ? (
                    <>
                      <div className="cw-heading-qna mb-10">
                        <p style={{ marginLeft: '30px', color: '#000' }}>
                          Select from our curated master list of questions
                        </p>
                      </div>
                      {/* <QuestionAnswerNewList
                            language={questionProps.campaignLanguageCode}
                            campaignLanguage={questionProps.campaignLanguage}
                            browseType='Questions'
                          /> */}
                      <QuestionAnswerNewOverlay
                        language={questionProps.campaignLanguageCode}
                        campaignLanguage={questionProps.campaignLanguage}
                        getNewQueCallback={getNewQueCallback}
                        rootIndex={index}
                        browseType="Questions"
                        isMasterData={isMasterData}
                        questionType={elem.value}
                      />
                    </>
                  ) : null}

                  {elem && (elem.englishLabel || elem.translatedLabel) ? (
                    <>
                      <div className="cw-qna-lable-width mb-10 mt-3">
                        <label htmlFor={`questionLabel_${index + 1}`}>
                          <span>Question (English - Master Data)</span>
                          {/* <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="This selection is required to choose existing master data or request new. Use keywords to look for existing master data. E.g Try searching for 'hair colour'"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span> */}
                        </label>

                        <div id={'questionDropdown'}>
                          {isEdited ? (
                            <input
                              type="text"
                              className="form-control"
                              name={'question'}
                              id={'question'}
                              disabled={true}
                              value={
                                elem && elem.englishLabel !== ''
                                  ? elem.englishLabel
                                  : ''
                              }
                            />
                          ) : (
                            <AsyncDropDown
                              className={'cw-select--custom'}
                              name={'question'}
                              label={'Question'}
                              placeholder={'Search for question using keywords'}
                              isDisabled={true}
                              minimumInput={3}
                              loadOptions={_getQuestions}
                              defaultOptions={questionProps.questions}
                              onChange={(e, actionMeta) =>
                                _handleQuestion(e, actionMeta, index)
                              }
                              isLoading={questionProps.fetchingQuestions}
                              value={
                                elem && elem.englishLabel !== ''
                                  ? {
                                      label: elem.englishLabel,
                                      value: elem.englishLabel
                                    }
                                  : null
                              }
                            />
                          )}
                        </div>
                        {elem.isNew ? (
                          <span className="cw-new-question-dropdown badge badge-warning">
                            New
                          </span>
                        ) : null}

                        <span
                          id={`questionDropdown_${index}`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please provide the question
                        </span>
                      </div>
                      {questionProps &&
                      questionProps.campaignLanguage !== 'English' ? (
                        <div
                          className="form-group cw-qna-group-field-width cw-qna-lable-width"
                          key={`translatedQ_${index + 1}`}
                        >
                          <label htmlFor={`questionTranslatedLabel_${index}`}>
                            <span>
                              Question (
                              {questionProps && questionProps.campaignLanguage}{' '}
                              translation - Master Data)
                            </span>
                            {/* <span
                                className="float-right  cw-icon cw-icon--help"
                                data-tip="This field will auto-populate master data if existing or else will be required to provide new, in local translation."
                              >
                                <i className="fas fa-question-circle"></i>
                              </span> */}
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              name={`questionTranslatedLabel_${index}`}
                              id={`questionTranslatedLabel_${index}`}
                              aria-describedby={`questionTranslatedLabel_${index}`}
                              disabled={isEdited}
                              data-required="true"
                              value={
                                elem.translatedLabel ? elem.translatedLabel : ''
                              }
                              onChange={e => onTranslationLabelChange(e, index)}
                            />
                            {elem.istranslatedNew ? (
                              <span className="cw-new badge badge-warning">
                                New
                              </span>
                            ) : null}
                          </div>
                          <span
                            id={`translationError_${index}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            No translation found. Please enter in{' '}
                            {questionProps && questionProps.campaignLanguage}{' '}
                            translation
                          </span>
                          <span
                            id={`questionTranslationError_${index}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please provide the translation of the question
                          </span>
                        </div>
                      ) : null}
                      <div
                        className="form-group  cw-qna-lable-width mb-10"
                        key={`questionLabel_${index + 1}`}
                      >
                        <label htmlFor={`questionLabel_${index}`}>
                          <span>Question Label (Consumer Facing)</span>
                          {/* <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="This is the label of the question that the consumers will see on the form"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span> */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name={`questionLabel_${index}`}
                          id={`questionLabel_${index}`}
                          aria-describedby={`questionLabel_${index}`}
                          data-required="true"
                          value={
                            questionProps.campaignLanguageCode !== 'EN'
                              ? elem.translatedLabel === elem.questionLabel
                                ? elem.translatedLabel
                                : elem.questionLabel
                              : elem.questionLabel === elem.englishLabel
                              ? elem.englishLabel
                              : elem.questionLabel
                          }
                          onChange={e => onLabelChange(e, index, elem)}
                        />
                        <span
                          id={`questionLabelError_${index}`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please provide the question label
                        </span>
                      </div>
                      {elem.reasonForCreation || elem.isNew ? (
                        <>
                          <div className="form-group  cw-qna-lable-width mb-10">
                            <label for="questionLabel_2">
                              <span>Reason for new master data request</span>
                              <ReactTooltip
                                id="new_master"
                                place="right"
                                type="info"
                                multiline={true}
                                className="cw-toolip custom-cw-toolip"
                                style={{
                                  width: '70% !important'
                                }}
                              />
                              <span
                                className="float-right  cw-icon cw-icon--help"
                                data-for="new_master"
                                data-tip="Mark your question request as “enrichment” if this data will help Unilever improve its understanding of a consumer to enrich or segment audience such as consumer interests, needs, preferences, usage of products, their benefits, etc. <br/> Else, mark your question as “operational” if it intends to help ensure the campaign works or to fulfill consumer requests, such as receipts, purchase date, SKUs, etc."
                                currentitem="false"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </label>
                            <div
                              className="form-group mt-10 mr-3 w-100 saas-profile-select saas-profile-select--qna-new-design mb-20"
                              id="add-questionField"
                            >
                              <Select
                                id={`reason_${index}`}
                                className={
                                  'cw-select--custom light-placeholder cw-color--primary'
                                }
                                placeholder={
                                  'Reason for the master data request'
                                }
                                options={questionTypesSelect}
                                onChange={e => handleSelectData(e, index)}
                                value={
                                  elem.reasonForCreation
                                    ? questionTypesSelect.find(
                                        e => e.value === elem.reasonForCreation
                                      )
                                    : null
                                }
                              />
                            </div>
                            <span
                              id={`ReasonForMasterdata_${index}`}
                              className="cw-error d-none"
                              data-control="name"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please select reason for new master data request
                            </span>
                          </div>
                          {isNewMasterDataRequested(elem) ? (
                            <AdditionalCommentsField
                              index={index}
                              value={elem.additionalComments}
                              onChange={onAdditionalCommentsChange}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}
                  <div className="mt-10 mb-20 ml-4 pl-2 generic-error-message">
                    <span
                      id={`questionError_${index}`}
                      className="cw-error cw-hidden"
                      data-control="name"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please select the question
                    </span>
                  </div>
                </div>

                <div className="cw-question-answer-dotted-line mt-15 cw-question-answer-v2">
                  {elem.value === 'freeText' ||
                  elem.value === 'freeTextArea' ? (
                    <>
                      <div className="form-group cw-question-answer-dotted-line">
                        <div className="cw-heading-qna mb-10">
                          <span
                            className="float-left  fa fa-circle mt-10"
                            style={{ marginTop: '5px' }}
                          ></span>
                          <strong style={{ marginLeft: '15px', color: '#000' }}>
                            Answers
                          </strong>
                        </div>
                        <div className="cw-qna-lable-width">
                          <span>
                            Answers for free text questions are entered by
                            consumers
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="cw-heading-qna mb-10">
                        <span
                          className="float-left  fa fa-circle mt-10"
                          style={{ marginTop: '5px' }}
                        ></span>
                        <strong style={{ marginLeft: '15px', color: '#000' }}>
                          Answer
                        </strong>
                      </div>
                      {!isEdited ? (
                        <>
                          <div className="cw-heading-qna mb-10">
                            <p style={{ marginLeft: '30px', color: '#000' }}>
                              Select from our curated master list of answers
                            </p>
                          </div>
                          {/* <QuestionAnswerNewList
                                language={questionProps.campaignLanguageCode}
                                campaignLanguage={questionProps.campaignLanguage}
                                browseType='Answers'
                              /> */}
                          <QuestionAnswerNewOverlay
                            language={questionProps.campaignLanguageCode}
                            campaignLanguage={questionProps.campaignLanguage}
                            getNewAnsCallback={getNewAnsCallback}
                            rootIndex={index}
                            browseType="Answers"
                            isMasterData={isMasterData}
                          />
                        </>
                      ) : null}

                      <div className="mt-3">
                        {elem.answers &&
                          elem.answers.map((answer, indexValue) => {
                            return (
                              <>
                                {/* <ReactTooltip
                                      place="right"
                                      type="info"
                                      multiline={true}
                                      className="cw-tooltip"
                                    /> */}
                                <div
                                  className="form-group"
                                  style={{
                                    marginLeft: '3.5%'
                                  }}
                                  key={`answer_${indexValue + 1}`}
                                >
                                  <label
                                    htmlFor={`translatedAnswerSelect_${index}`}
                                  >
                                    <span>
                                      <strong>
                                        &nbsp;&nbsp;Answer {indexValue + 1}{' '}
                                        (English - Master Data)
                                      </strong>
                                    </span>
                                    {/* <span
                                          className="float-right  cw-icon cw-icon--help"
                                          data-tip="This selection is required to choose existing master data or request new."
                                        >
                                          <i className="fas fa-question-circle"></i>
                                        </span> */}
                                  </label>
                                  {/* <p className="mb-10">
                                    <strong>
                                      Answer {indexValue + 1}{" "}
                                      (English - Master Data)
                                    </strong>
                                  </p> */}
                                  <div
                                    style={{
                                      margin: '0 0 0 1%',
                                      position: 'relative'
                                    }}
                                  >
                                    <div
                                      id={`answerDropdown_${index}_${indexValue}`}
                                    >
                                      {isEdited ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={'answer_' + indexValue}
                                          id={'answer_' + indexValue}
                                          disabled={true}
                                          value={
                                            elem.answers &&
                                            elem.answers[indexValue].label !==
                                              ''
                                              ? elem.answers[indexValue].label
                                              : ''
                                          }
                                        />
                                      ) : (
                                        <AsyncDropDown
                                          className={'cw-select--custom'}
                                          name={'answer_' + indexValue}
                                          label={'Answer_' + indexValue}
                                          placeholder={
                                            'Search for answers using keywords'
                                          }
                                          loadOptions={_getAnswers}
                                          defaultOptions={questionProps.answers}
                                          isLoading={
                                            questionProps.fetchingAnswers
                                          }
                                          onChange={(val, actionMeta) => {
                                            _handleAnswer(
                                              {
                                                index: indexValue,
                                                value: val.value,
                                                label: val.label
                                              },
                                              actionMeta,
                                              indexValue,
                                              index
                                            );
                                          }}
                                          value={
                                            elem.answers &&
                                            elem.answers[indexValue].label !==
                                              ''
                                              ? {
                                                  label:
                                                    elem.answers[indexValue]
                                                      .label,
                                                  value:
                                                    elem.answers[indexValue]
                                                      .value
                                                }
                                              : null
                                          }
                                        />
                                      )}
                                    </div>
                                    {elem.answers[indexValue].isNew ? (
                                      <span className="cw-new badge badge-warning">
                                        New
                                      </span>
                                    ) : null}
                                    {indexValue >= 2 ? (
                                      <span
                                        className="saas-accordion-close qna"
                                        data-index={index}
                                        onClick={e =>
                                          _deleteAnswer(index, indexValue)
                                        }
                                      ></span>
                                    ) : null}
                                    <span
                                      id={`answerDropdownError_${index}_${indexValue}`}
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please provide the answer
                                    </span>
                                  </div>
                                  {questionProps &&
                                  questionProps.campaignLanguage !==
                                    'English' ? (
                                    <div
                                      className="form-group form-group-field-width"
                                      key={`translatedanswer_${indexValue + 1}`}
                                    >
                                      <label className="mt-10">
                                        <span className="mb-10">
                                          Answer {indexValue + 1} {''}(
                                          {questionProps &&
                                          questionProps.campaignLanguage
                                            ? questionProps.campaignLanguage
                                            : ''}
                                          &nbsp;translation - Master Data)
                                        </span>
                                        {/* <span
                                              className="float-right  cw-icon cw-icon--help"
                                              data-tip="This field will auto-populate master data if existing or else will be required to provide new, in local translation."
                                            >
                                              <i className="fas fa-question-circle"></i>
                                            </span> */}
                                        {index !== 0 ? (
                                          <span
                                            className="cw-icon cw-icon--deleteAnswer"
                                            data-index={index}
                                          >
                                            {/* <i
                                              className="saas-accordion-close qna"
                                              className={`fas ${
                                                isDisabled
                                                  ? " fa-disabled "
                                                  : ""
                                              } fa-trash-alt`}
                                            ></i> */}
                                          </span>
                                        ) : null}
                                      </label>

                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control mb-10"
                                          name={`answerTranslatedLabel_${indexValue}`}
                                          id={`answerTranslatedLabel_${index}_${indexValue}`}
                                          aria-describedby={`answerTranslatedLabel_${indexValue}`}
                                          data-required="true"
                                          disabled={isEdited}
                                          value={
                                            questionProps.campaignLanguageCode !==
                                            'EN'
                                              ? elem.answers[indexValue]
                                                  .translatedLabel
                                              : ''
                                          }
                                          onChange={e =>
                                            handleTranslationAnswerLabel(
                                              e,
                                              index,
                                              indexValue
                                            )
                                          }
                                        />
                                        {elem.answers[indexValue]
                                          .istranslatedNew ? (
                                          <span className="cw-new badge badge-warning">
                                            New
                                          </span>
                                        ) : null}
                                      </div>
                                      <span
                                        id={`answerTranslationError_${index}_${indexValue}`}
                                        className="cw-error cw-hidden"
                                        data-control="name"
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Please provide the translation of the
                                        answer
                                      </span>
                                    </div>
                                  ) : null}

                                  <div
                                    className="form-group form-group-field-width"
                                    key={`answerLabel_${indexValue + 1}`}
                                  >
                                    <label className="mt-10">
                                      <span className="mb-10">
                                        Answer {indexValue + 1} Label (Consumer
                                        Facing)
                                      </span>
                                      {/* <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="This is the label of the answer that the consumers will see on the form"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span> */}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mb-10"
                                      name={`answerLabel_${indexValue}`}
                                      id={`answerLabel_${index}_${indexValue}`}
                                      aria-describedby={`answerLabel_${indexValue}`}
                                      data-required="true"
                                      value={
                                        questionProps.campaignLanguageCode !==
                                        'EN'
                                          ? elem.answers[indexValue]
                                              .translatedLabel ===
                                            elem.answers[indexValue].answerLabel
                                            ? elem.answers[indexValue]
                                                .translatedLabel
                                            : elem.answers[indexValue]
                                                .answerLabel
                                          : elem.answers[indexValue]
                                              .answerLabel ===
                                            elem.answers[indexValue]
                                              .englishLabel
                                          ? elem.answers[indexValue]
                                              .englishLabel
                                          : elem.answers[indexValue].answerLabel
                                      }
                                      onChange={e =>
                                        handleAnswerLabel(e, index, indexValue)
                                      }
                                    />
                                    <span
                                      id={`answerLabelError_${index}_${indexValue}`}
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please provide the answer label
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <div className="mt-10 mb-20 ml-4 pl-2 generic-error-message">
                        <span
                          id={`answerError_${index}`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please select at least two answers
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                className={
                  isMasterData &&
                  campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT
                    ? 'd-none'
                    : 'cw-question-answer-dotted-line'
                }
              >
                <div className="cw-heading-qna">
                  <span
                    className="float-left  fa fa-circle mt-10"
                    style={{ marginTop: '5px' }}
                  ></span>
                  <strong style={{ marginLeft: '15px', color: '#000' }}>
                    Other Configurations
                  </strong>
                </div>
                <Tooltip
                  qna
                  multipleChoice={elem.value === 'multipleChoice'}
                  v2
                  index={index}
                  value={elem && elem.toolTip ? elem.toolTip : ''}
                  onChange={e => handleToolTip(e, index)}
                />
                <div className="form-group d-flex ml-3 cw-qna-lable-width">
                  <label className="switch-saas">
                    <input
                      type="checkbox"
                      name="quesMandatory"
                      checked={elem && elem.mandatoryToggle ? true : false}
                      onChange={e => mandatoryQuestionHandler(e, index)}
                    />
                    <div className="slider-saas round"></div>
                  </label>
                  <label className=" ml-20" htmlFor="quesMandatory">
                    {elem && elem.mandatoryToggle
                      ? constants.MANDATORY_LABEL_MAP['mandatory']
                      : constants.MANDATORY_LABEL_MAP['optional']}
                  </label>
                </div>
                {elem && elem.mandatoryToggle ? (
                  <>
                    <div className="form-group ml-3 mt-2 cw-qna-lable-width">
                      <label htmlFor={`errorMessage_${index}`}>
                        <span>Mandatory Field Error Message</span>
                        <ReactTooltip
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                          id="mandatory"
                        />
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-for="mandatory"
                          data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors[`errorMessage_${index}`]
                            ? 'cw-error-focus'
                            : ''
                        }`}
                        name={`errorMessage_${index}`}
                        id={`errorMessage_${index}`}
                        aria-describedby={`errorMessage_${index}`}
                        data-required="true"
                        value={
                          elem && elem.mandatoryMessage
                            ? elem.mandatoryMessage
                            : ''
                        }
                        onChange={e => handleMandatoryMessage(e, index, elem)}
                      />
                      <span
                        className={`cw-error ${
                          errors[`errorMessage_${index}`] ? '' : 'cw-hidden'
                        }`}
                        id={`errorMessage_${elem.value}_${index}`}
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter mandatory message.
                      </span>
                    </div>
                  </>
                ) : null}
                {campaignType === 'Incentive' &&
                (elem.questionType === 'Single choice' ||
                  elem.questionType === 'Free text') ? (
                  <div>
                    <div className="form-group d-flex cw-qna-lable-width">
                      <label className="switch-saas">
                        <input
                          type="checkbox"
                          name="isQualifying"
                          disabled={
                            !elem.qualifyingToggle &&
                            qualifyingQuestionCount >= 5
                          }
                          checked={elem && elem.qualifyingToggle ? true : false}
                          onChange={e =>
                            qualifyingQuestionHandler(e, index, elem)
                          }
                        />
                        <div className="slider-saas round"></div>
                      </label>
                      <label className=" ml-20" htmlFor="isQualifying">
                        Make this a Qualifying question &nbsp;
                        <ReactTooltip
                          id="saas_qualifying_toggle_tooltip"
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                        />
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-for="saas_qualifying_toggle_tooltip"
                          data-tip={constants.QUALIFYING_TOGGLE_TOOLTIP}
                        >
                          <i className="fa fa-info-circle"></i>
                        </span>
                      </label>
                    </div>
                    <span
                      className={`cw-error ${
                        maximumQualifingQuestionsErrorIndices.includes(index)
                          ? ''
                          : 'cw-hidden'
                      } ml-4 `}
                      id={`errorMessage_qualifyingToogle_${index}`}
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      You can mark upto 5 questions as qualifying.
                    </span>
                  </div>
                ) : null}
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}

function arePropsEqual(oldProps, newProps) {
  return (
    oldProps.expand === newProps.expand &&
    oldProps.elem === newProps.elem &&
    oldProps.index === newProps.index &&
    oldProps.isValid === newProps.isValid &&
    oldProps.errors === newProps.errors &&
    oldProps.campaignType === newProps.campaignType &&
    oldProps.maximumQualifingQuestionsErrorIndices ===
      newProps.maximumQualifingQuestionsErrorIndices &&
    oldProps.isEdited === newProps.isEdited &&
    oldProps.elem === newProps.elem
  );
}

export default React.memo(QnAFieldv2, arePropsEqual);
