/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Tooltip from '../Tooltip/Tooltip';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';
import Alert from 'components/Alert';
import constants from 'constants/config';

function QnAFieldEpsilon({
  elem,
  isValid,
  errors,
  index,
  question,
  addQuestion,
  handleAccordionExpandCollapse,
  expand,
  isEdited,
  removeAccordion
}) {
  const [isMandatory, setMandatory] = useState(false);
  const [answersLimit, setMaxAnswerLimitOptions] = useState([]);

  let _handleMaxAnswersAllowed = (selectedOption, index) => {
    let maxAnswerLimit = {
      value: selectedOption.value,
      label: selectedOption.label
    };
    let questionSelected = [...question];
    questionSelected[index].maxAnswerLimit = maxAnswerLimit.value;
    addQuestion([...questionSelected]);
  };

  let onLabelChange = (e, index, elem) => {
    document.getElementById(`questionLabelError_${index}`) &&
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionLabel_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionLabel_${index}`)
        .classList.remove('cw-error-focus');

    let { campaignLanguageCode } = questionProps;
    let enteredText = e.target.value;
    let tranlatedAQ = [...question];
    tranlatedAQ[index].questionLabel = enteredText;
    addQuestion([...tranlatedAQ]);
  };

  let handleAnswerLabel = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let epsilonAnswerLabel = [...question];
    epsilonAnswerLabel[index].answers[indexValue].answerLabel = enteredText;

    document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerLabelError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerLabel_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    addQuestion([...epsilonAnswerLabel]);
  };

  let handleAnswerId = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let epsilonAnswerLabel = [...question];
    epsilonAnswerLabel[index].answers[indexValue].value = enteredText;

    document.getElementById(`answerId_Error_${index}_${indexValue}`) &&
      document
        .getElementById(`answerId_Error_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document.getElementById(`answerId_NumError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerId_NumError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerId_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerId_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    addQuestion([...epsilonAnswerLabel]);
  };

  let handleSurveyId = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let surveyAnswerId = [...question];
    surveyAnswerId[index].answers[
      indexValue
    ].epsilonQuestionAnswerId = enteredText;

    document.getElementById(`surveyId_error_${index}_${indexValue}`) &&
      document
        .getElementById(`surveyId_error_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document.getElementById(`surveyId_Numerror_${index}_${indexValue}`) &&
      document
        .getElementById(`surveyId_Numerror_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`surveyId_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`surveyId_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    addQuestion([...surveyAnswerId]);
  };

  let _addAnswer = (e, index) => {
    let answerNew = {
      value: '',
      answerLabel: '',
      epsilonQuestionAnswerId: ''
    };

    let questionArray = [...question];
    questionArray[index] &&
      questionArray[index].answers &&
      questionArray[index].answers.push(answerNew);
    addQuestion([...questionArray]);
  };

  let _deleteAnswer = async (index, indexValue) => {
    let questionArray = [...question];
    questionArray[index] &&
      questionArray[index].answers &&
      questionArray[index].answers.splice(indexValue, 1);
    addQuestion([...questionArray]);
  };

  let mandatoryQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    let mandatoryQ = [...question];
    mandatoryQ[index].mandatoryToggle = isChecked;
    addQuestion([...mandatoryQ]);
    setMandatory(event.target.checked);
  };

  let handleMandatoryMessage = (e, index, elem) => {
    let enteredText = e.target.value;
    if (enteredText.trim().length) {
      document.getElementById(`errorMessage_${elem.value}_${index}`) &&
        document
          .getElementById(`errorMessage_${elem.value}_${index}`)
          .classList.add('cw-hidden');

      document
        .getElementById(`errorMessage_${index}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`errorMessage_${index}`)
          .classList.remove('cw-error-focus');
    } else {
      document.getElementById(`errorMessage_${elem.value}_${index}`) &&
        document
          .getElementById(`errorMessage_${elem.value}_${index}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`errorMessage_${index}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`errorMessage_${index}`)
          .classList.add('cw-error-focus');
    }
    let mandatoryQ = [...question];
    mandatoryQ[index].mandatoryMessage = mandatoryQ[index].mandatoryToggle
      ? enteredText
      : '';
    addQuestion([...mandatoryQ]);
  };

  let handleToolTip = (e, index) => {
    let enteredText = e.target.value;
    let toolTipQ = [...question];
    toolTipQ[index].toolTip = enteredText;
    addQuestion([...toolTipQ]);
  };

  let _handleQuestionId = (e, index) => {
    if (document.getElementById(`questionIdError_${index}`)) {
      document
        .getElementById(`questionIdError_${index}`)
        .classList.add('cw-hidden');
    }
    document.getElementById(`questionIdNumError_${index}`) &&
      document
        .getElementById(`questionIdNumError_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionId_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionId_${index}`)
        .classList.remove('cw-error-focus');

    let updatedQuestion = [...question];
    updatedQuestion[index].id = e.target.value;
    addQuestion([...updatedQuestion]);
  };

  const questionProps = useSelector(state => ({
    approverEditCampaign: state.app.approverEditCampaign,
    questionanswerssaved: state.questionsAnswers.questionanswerssaved,
    campaignLanguage: state.questionsAnswers.campaignLanguage,
    campaignLanguageCode: state.questionsAnswers.campaignLanguageCode,
    editCampaign: state.overview.editCampaign,
    editCampaignDetails: state.app.editCampaignDetails,
    isCampaignEdited: state.questionsAnswers.isCampaignEdited,
    websiteType: state.questionsAnswers.websiteType,
    isLanguageEnglish: state.questionsAnswers.isLanguageEnglish,
    campaignType: state.questionsAnswers.campaignType,
    autogenerate: state.campaignDetails.autogenerate,
    type: state.campaignDetails.type,
    incentiveType: state.campaignDetails.incentiveType,
    campaign: state.questionsAnswers.campaign,
    fields: state.questionsAnswers.fields,
    formMetaData: state.saasFormAuthoring
  }));

  return (
    <div
      id={`accordion_${elem.value}_${index + 1}`}
      className="accordion saas-form-field-accordion field"
      key={`question_${index + 1}`}
    >
      <ReactTooltip
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <ErrorIcon isValid={isValid} />
      <div
        className="card"
        style={{
          border: 0,
          overflow: 'visible',
          width: '100%'
        }}
      >
        <div className="card-header">
          <span
            className="saas-accordion-heading cw-color--primary"
            style={{ fontWeight: '700' }}
          >
            {question[index].questionLabel
              ? question[index].questionLabel.length < 70
                ? question[index].questionLabel
                : question[index].questionLabel.substring(0, 70) + '...'
              : elem.label}
          </span>
          <span
            type="button"
            className={`saas-accordion-arrow ${index}`}
            onClick={() => handleAccordionExpandCollapse(index)}
          >
            <i
              className={
                expand === index
                  ? 'fas fa-chevron-down transformIcon-180'
                  : 'fas fa-chevron-down'
              }
            ></i>
          </span>
          {!isEdited ||
          question.filter(elem => elem.dataType === 'questionAnswerPair')
            .length > 1 ? (
            <span
              className={'saas-accordion-close'}
              onClick={() => {
                removeAccordion(index);
              }}
              style={{ right: '-4%', zIndex: '1' }}
            ></span>
          ) : null}
        </div>
        <div className={expand === index ? 'collapse show' : 'collapse'}>
          <ReactTooltip
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
          <>
            {elem.value === 'freeTextArea' && (
              <div className="custon-alert-msg mt-10 mb-10">
                <Alert
                  alertType="saasInfoMessageWithIcon"
                  // showComponent={true}
                  textMessage={constants.FREE_TEXT_AREA_BLUE_WARNING}
                />
              </div>
            )}

            <div className="cw-question-answer-dotted-line mt-15">
              <div className="cw-heading-qna mb-10">
                <span
                  className="float-left  fa fa-circle mt-10"
                  style={{ marginTop: '5px' }}
                ></span>
                <strong style={{ marginLeft: '15px' }}>Question</strong>
              </div>
              <div
                className="form-group cw-qna-lable-width"
                key={`questionLabel_${index + 1}`}
              >
                <label htmlFor={`questionLabel_${index}`}>
                  <span>Question Label (Consumer Facing)</span>
                  <span
                    className="float-right  cw-icon cw-icon--help"
                    data-tip="This is the label of the question that the consumers will see on the form"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name={`questionLabel_${index}`}
                  id={`questionLabel_${index}`}
                  aria-describedby={`questionLabel_${index}`}
                  data-required="true"
                  defaultValue={question[index].questionLabel}
                  onChange={e => onLabelChange(e, index, elem)}
                />
                <span
                  id={`questionLabelError_${index}`}
                  className="cw-error cw-hidden"
                  data-control="name"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  Please enter Question Label
                </span>
              </div>
              <div className="form-group cw-qna-lable-width">
                <label htmlFor={`questionLabel_${index + 1}`}>
                  <span>Question ID</span>
                  <span
                    className="float-right  cw-icon cw-icon--help"
                    data-tip="Please add Question ID (master data) as provided by Epsilon in your Campaign Template (CT)"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </label>

                <input
                  type="text"
                  maxLength={16}
                  className="form-control"
                  name={`questionId_${index}`}
                  id={`questionId_${index}`}
                  aria-describedby={`questionId_${index}`}
                  data-required="true"
                  onChange={e => _handleQuestionId(e, index)}
                  defaultValue={question[index].id}
                />

                <span
                  id={`questionIdError_${index}`}
                  className="cw-error cw-hidden"
                  data-control="name"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  Please enter Question ID
                </span>

                <span
                  id={`questionIdNumError_${index}`}
                  className="cw-error cw-hidden"
                  data-control="name"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  Please enter a numeric value
                </span>
              </div>
            </div>

            <>
              <div className="form-group cw-question-answer-dotted-line mt-15">
                <div className="cw-heading-qna mb-10">
                  <span
                    className="float-left  fa fa-circle mt-10"
                    style={{ marginTop: '5px' }}
                  ></span>
                  <strong style={{ marginLeft: '15px' }}>Answers</strong>
                </div>
                {/* <div
                    className="cw-heading--saascampaign mt-4"
                    style={{ marginLeft: "-3px" }}
                  >
                    &nbsp; Configure Answers
                  </div> */}

                {question[index].answers &&
                  question[index].answers.map((ele, indexValue) => {
                    return (
                      <>
                        <ReactTooltip
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                        />
                        {elem.value !== 'freeText' &&
                        elem.value !== 'freeTextArea' ? (
                          <div
                            className="form-group cw-qna-lable-width"
                            key={`answer_${indexValue + 1}`}
                          >
                            <label className="mt-10">
                              <span className="mb-10">
                                Answer {indexValue + 1} Label (Consumer Facing)
                              </span>
                              <span
                                className="float-right  cw-icon cw-icon--help"
                                data-tip="This is the label of the answer that the consumers will see on the form"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control mb-10"
                              name={`answerLabel_${indexValue}`}
                              id={`answerLabel_${index}_${indexValue}`}
                              aria-describedby={`answerLabel_${indexValue}`}
                              data-required="true"
                              defaultValue={
                                question[index].answers[indexValue].answerLabel
                              }
                              onChange={e =>
                                handleAnswerLabel(e, index, indexValue)
                              }
                            />
                            {indexValue >= 2 ? (
                              <span
                                className={`saas-accordion-close epsilon-qna-close`}
                                data-index={index}
                                onClick={e => _deleteAnswer(index, indexValue)}
                              ></span>
                            ) : null}
                            <span
                              id={`answerLabelError_${index}_${indexValue}`}
                              className="cw-error cw-hidden"
                              data-control="name"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter Answer Label
                            </span>
                          </div>
                        ) : null}

                        <div
                          className="form-group cw-qna-lable-width"
                          key={`answerId_${indexValue + 1}`}
                        >
                          <label className="mt-10">
                            <span className="mb-10">
                              {elem.value !== 'freeText' &&
                              elem.value !== 'freeTextArea'
                                ? `Answer ${indexValue + 1} ID`
                                : `Answer ID`}
                            </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Please add Answer ID (master data) as provided by Epsilon in your Campaign Template (CT)"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            maxLength={16}
                            className="form-control mb-10"
                            name={`answerId_${indexValue}`}
                            id={`answerId_${index}_${indexValue}`}
                            aria-describedby={`answerId_${indexValue}`}
                            data-required="true"
                            defaultValue={
                              question[index].answers[indexValue].value
                            }
                            onChange={e => handleAnswerId(e, index, indexValue)}
                          />
                          <span
                            id={`answerId_Error_${index}_${indexValue}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter the Answer ID
                          </span>
                          <span
                            id={`answerId_NumError_${index}_${indexValue}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a numeric value
                          </span>
                        </div>

                        <div
                          className="form-group cw-qna-lable-width"
                          key={`surveyId_${indexValue + 1}`}
                        >
                          <label className="mt-10">
                            <span className="mb-10">
                              {elem.value !== 'freeText' &&
                              elem.value !== 'freeTextArea'
                                ? `Survey Question Answer ${indexValue + 1} ID`
                                : `Survey Question Answer ID`}
                            </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Please add Survey Question Answer ID (master data) as provided by Epsilon in your Campaign Template (CT)"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            maxLength={16}
                            className="form-control mb-10"
                            name={`surveyId_${indexValue}`}
                            id={`surveyId_${index}_${indexValue}`}
                            aria-describedby={`surveyId_${indexValue}`}
                            data-required="true"
                            defaultValue={
                              question[index].answers[indexValue]
                                .epsilonQuestionAnswerId
                            }
                            onChange={e => handleSurveyId(e, index, indexValue)}
                          />
                          <span
                            id={`surveyId_error_${index}_${indexValue}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter Survey Question Answer ID
                          </span>
                          <span
                            id={`surveyId_Numerror_${index}_${indexValue}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a numeric value
                          </span>
                        </div>
                      </>
                    );
                  })}
                {!isEdited &&
                elem.value !== 'freeText' &&
                elem.value !== 'freeTextArea' ? (
                  <button
                    onClick={e => _addAnswer(e, index)}
                    className="btn btn-outline-primary"
                    style={{ marginLeft: '4.5%' }}
                  >
                    Add another answer
                  </button>
                ) : null}
                {elem.value === 'multipleChoice' ? (
                  <>
                    <ReactTooltip
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip"
                    />
                    <div
                      className="form-group cw-hidden"
                      style={{ margin: '1% 0 0 2%' }}
                    >
                      <label htmlFor="answerLimit">
                        <span>Maximum number of answers user can select</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Limit the number of answers the user can select this question"
                        >
                          <i className="fa fa-question-circle"></i>
                        </span>
                      </label>
                      <Select
                        className={'cw-select--custom'}
                        name={'answerLimit'}
                        label={'answerLimit'}
                        placeholder={'Select option'}
                        options={answersLimit}
                        onChange={e => _handleMaxAnswersAllowed(e, index)}
                        value={
                          question[index] &&
                          question[index].maxAnswerLimit !== ''
                            ? {
                                label: question[index].maxAnswerLimit,
                                value: question[index].maxAnswerLimit
                              }
                            : {
                                label: 'No limit',
                                value: ''
                              }
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control="answerLimit"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select maximum answers.
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </>
            <div className="cw-question-answer-dotted-line">
              <div className="cw-heading-qna">
                <span
                  className="float-left  fa fa-circle mt-10"
                  style={{ marginTop: '5px' }}
                ></span>
                <strong style={{ marginLeft: '15px' }}>
                  Other Configurations
                </strong>
              </div>
              <Tooltip
                qna
                multipleChoice={elem.value === 'multipleChoice'}
                index={index}
                epsilon
                value={
                  question[index] && question[index].toolTip
                    ? question[index].toolTip
                    : ''
                }
                onChange={e => handleToolTip(e, index)}
              />
              <div className="form-group d-flex ml-2 cw-qna-lable-width">
                <label className="switch-saas">
                  <input
                    type="checkbox"
                    name="quesMandatory"
                    checked={
                      question[index] && question[index].mandatoryToggle
                        ? true
                        : false
                    }
                    onChange={e => mandatoryQuestionHandler(e, index)}
                  />
                  <div className="slider-saas round"></div>
                </label>
                <label className=" ml-2" htmlFor="quesMandatory">
                {question[index] && question[index].mandatoryToggle
                      ? constants.MANDATORY_LABEL_MAP['mandatory']
                      : constants.MANDATORY_LABEL_MAP['optional']}
                </label>
              </div>
              {question[index] && question[index].mandatoryToggle ? (
                <>
                  <ReactTooltip
                    place="right"
                    type="info"
                    multiline={true}
                    className="cw-tooltip"
                  />
                  <div className="form-group ml-2 mt-2 cw-qna-lable-width">
                    <label htmlFor={`errorMessage_${index}`}>
                      <span>Mandatory Field Error Message</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                        currentitem="false"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors[`errorMessage_${index}`] ? 'cw-error-focus' : ''
                      }`}
                      name={`errorMessage_${index}`}
                      id={`errorMessage_${index}`}
                      aria-describedby={`errorMessage_${index}`}
                      data-required="true"
                      value={
                        question[index] && question[index].mandatoryMessage
                          ? question[index].mandatoryMessage
                          : ''
                      }
                      onChange={e => handleMandatoryMessage(e, index, elem)}
                    />
                    <span
                      className={`cw-error ${
                        errors[`errorMessage_${index}`] ? '' : 'cw-hidden'
                      }`}
                      id={`errorMessage_${elem.value}_${index}`}
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter the error message
                    </span>
                  </div>
                </>
              ) : null}
              {false ? (
                <div className="form-group d-flex cw-qna-lable-width">
                  <label className="switch-saas">
                    <input type="checkbox" name="isQualifying" />
                    <div className="slider-saas round"></div>
                  </label>
                  <label className=" ml-20" htmlFor="isQualifying">
                    Make this a Qualifying question &nbsp;
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="A qualifying question is a mandatory question that needs to be answered by the end consumer and is then validated or judged in order for that entry to be valid"
                    >
                      <i className="fa fa-question-circle"></i>
                    </span>
                  </label>
                </div>
              ) : null}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default QnAFieldEpsilon;
