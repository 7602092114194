import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import Select from 'react-select';
import Tooltip from '../Tooltip/Tooltip';
import RichTextEditor from '../../richTextEditor';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';
import Alert from '../../../components/Alert';
import constants from 'constants/config';

export default function ProfileField({
  errors,
  isValid,
  elem,
  index,
  epsilonMarket,
  countrySelected,
  profileFieldErrors,
  stateTypeOptions,
  constant,
  expand,
  profile,
  genderData,
  modalIsOpen,
  provinces,
  genderErrorMessage,
  genderFieldErrorMessage,
  profileRichText,
  isGenderFieldLabelErrorMessage,
  _openModal,
  handleAccordionExpandCollapse,
  removeAccordion,
  nonRemovableField,
  onLabelChange,
  _closeModal,
  _handleQuestionType,
  _deleteProvince,
  handleProvinceLabel,
  _addProvince,
  genderOnchange,
  genderInputHandler,
  onToolTipChange,
  mandatoryToggleOnChange,
  onErrorMessageChange,
  isLegalAge,
  _handleInputChange,
  richTextData,
  onLegalAgeValidationChange,
  onMobileRegexChange,
  onHeadingChange,
  isMasterData,
  campaignConfig
}) {
  return (
    <>
      <div
        id={`accordion_${elem.dataType}`}
        className="accordion saas-form-field-accordion field"
        key={`${elem.dataType}_${index}`}
      >
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip"
        />
        <ErrorIcon isValid={isValid} id={`profile_${elem.dataType}_${index}`}/>
        <div className="card" style={{ border: 0, overflow: 'visible' }}>
          <div className="card-header-wrapper">
            <div className="card-header">
              <span
                className="saas-accordion-heading cw-color--primary"
                style={{ fontWeight: '700' }}
              >
                {elem.label}
              </span>
              <span
                type="button"
                className={
                  isMasterData &&
                  campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT
                    ? 'd-none'
                    : `saas-accordion-arrow ${index}`
                }
                onClick={() => handleAccordionExpandCollapse(index)}
              >
                <i
                  className={
                    expand === index
                      ? 'fas fa-chevron-down transformIcon-180'
                      : 'fas fa-chevron-down'
                  }
                ></i>
              </span>
              {(epsilonMarket(countrySelected) && elem.dataType === 'email') ||
              elem.dataType === nonRemovableField ? null : (
                <span
                  id={`close_${elem.dataType}`}
                  className={'saas-accordion-close'}
                  onClick={() => {
                    removeAccordion(index);
                  }}
                ></span>
              )}
            </div>
          </div>
          <div className={expand === index ? 'collapse show' : 'collapse'}>
            <ReactTooltip
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
            {elem.label !== 'Heading' && elem.label !== 'Paragraph' ? (
              <>
                {elem.dataType === 'mobileNumber' ? (
                  <div className="form-group">
                    <Alert
                      alertType="saasInfoMessage"
                      withIcon
                      textMessage={
                        constant.SAAS_MESSAGES.smsConsentCollectionMessage
                      }
                    />
                  </div>
                ) : null}
                <div
                  className="form-group form-group-field-width"
                  key={`${elem.dataType}_${index}`}
                >
                  <label htmlFor={`fieldLabel_${elem.dataType}`}>
                    <span>Field Label</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`fieldLabel_${elem.dataType}`]
                        ? 'cw-error-focus'
                        : ''
                    }`}
                    name={`fieldLabel_${elem.dataType}`}
                    id={`fieldLabel_${elem.dataType}`}
                    maxLength="50"
                    aria-describedby={`fieldLabel_${elem.dataType}`}
                    data-required="true"
                    defaultValue={
                      profile[index].fieldLabel
                        ? profile[index].fieldLabel
                        : elem.label === 'Gender'
                        ? genderData.translatedLabel
                        : profile[index].translatedLabel
                        ? profile[index].translatedLabel
                        : ''
                    }
                    onChange={e => onLabelChange(e, index, elem)}
                  />
                  <span
                    id={`labelError_${elem.dataType}`}
                    className={`cw-error ${
                      errors[`fieldLabel_${elem.dataType}`] ? '' : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the field label
                  </span>
                  {(elem.dataType === 'gender' || elem.label === 'Gender') &&
                  isGenderFieldLabelErrorMessage ? (
                    <span className="cw-error" data-control="name">
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter gender label
                    </span>
                  ) : null}
                </div>
                {elem.dataType === 'stateOrProvince' ? (
                  <div className="form-group form-group-field-width ">
                    <label htmlFor="state_type">
                      <span>Field Type</span>
                      <span className="float-right  cw-icon cw-icon--help">
                        <i
                          className="fa fa-info-circle"
                          onClick={_openModal}
                        ></i>
                      </span>
                    </label>
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={_closeModal}
                      className="cw-modal cw-modal--questions"
                      contentLabel="Field Types"
                      style={{
                        overlay: {
                          backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                        }
                      }}
                    >
                      <button
                        className="cw-modal--close"
                        title="Close"
                        onClick={_closeModal}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      <h3 className="cw-heading--primary mb-10">Field Types</h3>
                      <p className="mb-20">
                        Choose from 2 different field types listed below
                      </p>
                      <div className="col-sm-12">
                        <div className="row cw-bgpattern-primary">
                          <div className="col-sm-4">
                            <strong>Text field</strong>
                          </div>
                          <div className="col-sm-8">
                            <span>
                              Input text fields that require consumers to type
                              in their responses
                            </span>
                          </div>
                        </div>
                        <div className="row cw-bgpattern-primary">
                          <div className="col-sm-4">
                            <strong>Single choice</strong>
                          </div>
                          <div className="col-sm-8">
                            <span>
                              A field with pre-defined values presented to
                              consumers for selection in the form of a dropdown
                              or a radio button. Consumers can choose only one
                              value
                            </span>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <Select
                      className={'mb-10 cw-select--custom cw-color--primary '}
                      name="stateType"
                      id="stateType"
                      // ref="stateType"
                      placeholder={'Select option'}
                      aria-describedby="stateType"
                      options={stateTypeOptions}
                      onChange={e => _handleQuestionType(e, index, elem)}
                      value={stateTypeOptions.filter(
                        option =>
                          option.value ===
                          (elem.questionType && elem.questionType !== undefined
                            ? elem.questionType
                            : 'Free text')
                      )}
                    />
                    {elem.questionType === 'Single choice' ? (
                      <>
                        <label htmlFor="state_values">
                          <span>Define values</span>
                        </label>
                        {provinces &&
                          provinces.map((provinceObj, indexValue) => {
                            return (
                              <>
                                <div key={indexValue}>
                                  <div className="form-group ">
                                    {indexValue >= 1 ? (
                                      <span
                                        style={{
                                          right: '-4%',
                                          top: '10%'
                                        }}
                                        className="saas-accordion-close"
                                        data-index={indexValue}
                                        onClick={e =>
                                          _deleteProvince(indexValue)
                                        }
                                      ></span>
                                    ) : null}
                                    <input
                                      type="text"
                                      placeholder="Enter a value"
                                      className={`form-control mb-20 ${
                                        errors[
                                          `answerTranslatedLabel_${indexValue}`
                                        ]
                                          ? 'cw-error-focus'
                                          : ''
                                      }`}
                                      name={`answerTranslatedLabel_${indexValue}`}
                                      id={`answerTranslatedLabel_${indexValue}`}
                                      aria-describedby={`answerTranslatedLabel_${indexValue}`}
                                      data-required="true"
                                      maxLength={
                                        epsilonMarket(countrySelected)
                                          ? '50'
                                          : '75'
                                      }
                                      value={
                                        provinceObj && provinceObj.value
                                          ? provinceObj.value
                                          : ''
                                      }
                                      onChange={e =>
                                        handleProvinceLabel(
                                          e,
                                          indexValue,
                                          index
                                        )
                                      }
                                    />
                                    <span
                                      className={`cw-error ${
                                        errors[
                                          `province_Error_Message_${indexValue}`
                                        ]
                                          ? ''
                                          : 'cw-hidden'
                                      }`}
                                      id={`province_Error_Message_${indexValue}`}
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter a value or remove the blank
                                      fields
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        <button
                          onClick={e => _addProvince(e)}
                          className="btn btn-primary mb-10"
                        >
                          Add values
                        </button>
                      </>
                    ) : null}
                  </div>
                ) : null}

                {elem.dataType === 'gender' || elem.label === 'Gender' ? (
                  <>
                    {elem.dataType === 'gender' || elem.label === 'Gender' ? (
                      <div className="form-group-field-width">
                        Configure the gender options you want to show on your
                        form
                      </div>
                    ) : null}

                    {elem.values.map((el, genderIndex) => {
                      return (
                        <div className="form-group">
                          <div className="row mb-1">
                            <div className="col-4">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultChecked={el.isChecked}
                                  onChange={e =>
                                    genderOnchange(e, index, genderIndex)
                                  }
                                  name={el.value}
                                  id={el.value}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={el.value}
                                >
                                  {el.value}
                                </label>
                              </div>
                            </div>
                            <div className="col-6">
                              {el.isChecked === true ? (
                                <input
                                  type="text"
                                  id={`gender_${el.value}`}
                                  name={el.value}
                                  defaultValue={el.translatedValue}
                                  onChange={e =>
                                    genderInputHandler(e, index, genderIndex)
                                  }
                                  className={`form-control ${
                                    errors[`gender_${el.value}`]
                                      ? 'cw-error-focus'
                                      : ''
                                  }`}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {genderErrorMessage ? (
                      <span className="cw-error" data-control="name">
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Select at-least two options
                      </span>
                    ) : null}

                    {genderFieldErrorMessage ? (
                      <span className="cw-error" data-control="name">
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the label for the selected gender options
                      </span>
                    ) : null}
                  </>
                ) : null}

                {elem.label === 'Gender' ? '' : null}
                <Tooltip
                  index={index}
                  value={profile[index].toolTipText}
                  onChange={e => onToolTipChange(e, index, elem)}
                />
                {console.log("profile[index].mandatoryToggle",profile[index].mandatoryToggle)}
                <div className="form-group form-group-field-width">
                  <div className="form-group d-flex ">
                    <label className="switch-saas">
                      <input
                        //   disabled={
                        //     (elem.dataType === "email" && this.isElementPresent("email") && !this.isElementPresent("mobileNumber"))
                        // || (elem.dataType === "mobileNumber" && !this.isElementPresent("email") && this.isElementPresent("mobileNumber"))
                        //  }
                        type="checkbox"
                        name={`mandateField_${index}`}
                        id={`mandatoryToggle_${elem.dataType}`}
                        checked={profile[index].mandatoryToggle}
                        disabled={
                          (epsilonMarket(countrySelected) &&
                            elem.dataType === 'email') ||
                          elem.dataType === nonRemovableField
                            ? true
                            : false
                        }
                        onChange={mandatoryToggleOnChange}
                      />
                      <div className="slider-saas round"></div>
                    </label>
                    <label className="ml-20" htmlFor={`mandateField_${index}`}>
                      {/* Make this field mandatory */}
                     {profile[index].mandatoryToggle  ? constants.MANDATORY_LABEL_MAP['mandatory'] : constants.MANDATORY_LABEL_MAP['optional']} 
                    </label>
                  </div>
                </div>
                <div
                  className={
                    profile[index].mandatoryToggle
                      ? 'form-group form-group-field-width'
                      : 'cw-hidden'
                  }
                >
                  <label htmlFor={`errorMessageText_${elem.dataType}`}>
                    <span>Mandatory Field Error Message</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`errorMessageText_${elem.dataType}`]
                        ? 'cw-error-focus'
                        : ''
                    }`}
                    name={`errorMessageText_${elem.dataType}`}
                    id={`errorMessageText_${elem.dataType}`}
                    aria-describedby={`errorMessageText_${elem.dataType}`}
                    data-required="true"
                    defaultValue={profile[index].mandatoryMessage}
                    onChange={e => onErrorMessageChange(e, index, elem)}
                  />
                  <span
                    id={`error_label_${elem.dataType}`}
                    className={`cw-error ${
                      errors[`errorMessageText_${elem.dataType}`]
                        ? ''
                        : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the error message
                  </span>
                </div>
              </>
            ) : null}
            {elem.label === 'Date of Birth' ? (
              <>
                <div className="form-group form-group-field-width">
                  <label htmlFor={`legalAgeValidation_${elem.dataType}`}>
                    <span>Minimum legal age of consent </span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="We will use this to ensure anyone who selects a lower age cannot sign-up"
                    >
                      <i className="fa fa-question-circle"></i>
                    </span>
                  </label>
                  <div id={`legalAgeValidation_${elem.dataType}`}>
                    <Select
                      className={'cw-select--custom cw-color--primary '}
                      name={`legalAgeValidation_${elem.dataType}`}
                      label={'Label'}
                      placeholder={isLegalAge}
                      options={constant.LEGAL_AGE_VALUES.map(ele => {
                        return {
                          label: ele,
                          value: ele
                        };
                      })}
                      onChange={e => _handleInputChange(e, elem, index)}
                      value={isLegalAge}
                    />
                  </div>
                  <span
                    id={`error_legalAgeValidation_${elem.dataType}`}
                    className="cw-error cw-hidden"
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    You need to be atleast{' '}
                    {epsilonMarket(countrySelected) ? 13 : 16} years old to
                    participate
                  </span>
                </div>
                <div className="form-group form-group-field-width">
                  <label htmlFor={`formatValidation_${elem.dataType}`}>
                    <span>Legal Age Validation Message</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="This the error that consumers will see if they try to select a date of birth that makes their age lower than the legal age consent."
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`ageValidation_${elem.dataType}`]
                        ? 'cw-error-focus'
                        : ''
                    }`}
                    name={`ageValidation_${elem.dataType}`}
                    id={`ageValidation_${elem.dataType}`}
                    maxLength="50"
                    aria-describedby={`ageValidation_${elem.dataType}`}
                    data-required="true"
                    value={profile[index].validationMessage || ''}
                    onChange={e => onLegalAgeValidationChange(e, index, elem)}
                  />
                  <span
                    id={`error_ageValidation_${elem.dataType}`}
                    className={`cw-error ${
                      errors[`ageValidation_${elem.dataType}`]
                        ? ''
                        : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter legal age validation message
                  </span>
                </div>
              </>
            ) : null}
            {elem.label === 'Mobile Number' ||
            elem.label === 'Postal Code' ||
            elem.label === 'Email Address' ? (
              <div
                className="form-group form-group-field-width"
                key={`${elem.dataType}_${index}`}
              >
                <label htmlFor={`formatValidation_${elem.dataType}`}>
                  <span>Format Validation Error Message</span>
                  <span
                    className="float-right  cw-icon cw-icon--help"
                    data-tip="Please specify the error message you would want the users to see in case they have filled in a wrong format. Make sure to have this message in the language you selected for the form."
                    currentitem="false"
                  >
                    <i className="fas fa-question-circle"></i>
                  </span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors[`formatValidation_${elem.dataType}`]
                      ? 'cw-error-focus'
                      : ''
                  }`}
                  name={`formatValidation_${elem.dataType}`}
                  id={`formatValidation_${elem.dataType}`}
                  maxLength="50"
                  aria-describedby={`formatValidation_${elem.dataType}`}
                  data-required="true"
                  defaultValue={profile[index].validationMessage}
                  onChange={e => onMobileRegexChange(e, index, elem)}
                />
                <span
                  id={`validation_error_label_${elem.dataType}_${index}`}
                  className={`cw-error ${
                    errors[`formatValidation_${elem.dataType}`]
                      ? ''
                      : 'cw-hidden'
                  }`}
                  data-control="name"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  Please enter the format validation error message
                </span>
              </div>
            ) : null}
            {elem.value === 'heading' || elem.label === 'Heading' ? (
              <>
                <div
                  className="form-group form-group-field-width"
                  key={`${elem.dataType}_${index}`}
                >
                  <label htmlFor={`heading_${index}`}>
                    <span>Heading Text</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Use this field to specify any custom headings to separate sections or fields of your form"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`heading_error_${elem.dataType}_${index}`]
                        ? 'cw-error-focus'
                        : ''
                    }`}
                    name={`heading_${index}`}
                    id={`heading_${index}`}
                    maxLength="50"
                    // ref={`heading_${index}`}
                    aria-describedby={`heading_${index}`}
                    data-required="true"
                    value={profile[index].heading || ''}
                    onChange={e => onHeadingChange(e, index, elem)}
                  />
                  <span
                    id={`heading_error_${elem.dataType}_${index}`}
                    className={`cw-error ${
                      errors[`heading_error_${elem.dataType}_${index}`]
                        ? ''
                        : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the heading statement
                  </span>
                </div>
              </>
            ) : null}
            {elem.value === 'paragraph' || elem.label === 'Paragraph' ? (
              <>
                <div
                  className="form-group form-group-field-width"
                  key={`${elem.dataType}_${index}`}
                >
                  <label htmlFor={`paragraph_${index}`}>
                    <span>Paragraph Text</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Use this field to specify custom paragraph text to appear between the fields on the form"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <div id={`paragraph_${index}`}>
                    <RichTextEditor
                      defaultValue={
                        profileRichText &&
                        profileRichText[index] &&
                        profileRichText[index].paragraph
                          ? profileRichText[index].paragraph
                          : ''
                      }
                      parentCallback={textData => {
                        richTextData(textData, index, elem);
                      }}
                    />
                  </div>
                  <span
                    id={`paragraph_error_${elem.dataType}_${index}`}
                    className="cw-error cw-hidden"
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the paragraph statement
                  </span>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
