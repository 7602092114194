import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import {
  fetchCampaignsList,
  getBrandCountries,
  getBrands,
  getWebsiteTypes
} from '../actions/dashboard';
import { resetAppProps } from '../actions/app';
import { setCampaignToEdit } from '../actions/overview';
import classNames from 'classnames';
import ServiceError from './ServiceError';
import {
  getFormatedDate,
  getSplitDashboardArray,
  sortArray,
  getCampaignStatus,
  isAEMTemplateCampaign,
  getUserCountryList,
  getCampaignStatusText,
  getDateRange,
  generateRoleStatusBasedUrl
} from '../selectors';
import constant from '../constants';
import RadioDropDown from './RadioDropDown';
import CheckboxDropDown from './CheckboxDropDown';

import NotificationMessage from './Messages';
import AdvanceSearch from './AdvanceSearch';
import CampaignList from './organisms/CampaignList/CampaignList';
import DeleteIcon from '../images/times.png';
import ReactTooltip from 'react-tooltip';
import CheckBoxInput from 'components/molecules/CheckBoxInput';

//import { css, cx } from 'emotion';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortClass: 'cw-sort cw-sort--asc',
      status: '',
      country: '',
      isOpen: false,
      value: undefined,
      defaultFiltersEnabled: true,
      isAdvFilterOpened: false,
      showFilters: false,
      showMyCampaigns: false,
      showLiveCampaigns: false
    };
    this.filters = {};
    this.body = {};
    this.showAdvanceSearch = false;
    this.campaignTypes = [
      {
        value: 'Direct messaging',
        incentiveType: null,
        label: 'Simple sign up'
      },
      {
        value: 'Incentive samples',
        incentiveType: 'Product samples',
        label: 'Incentive samples'
      },
      {
        value: 'Incentive competitions',
        incentiveType: 'Competitions',
        label: 'Incentive competitions'
      }
    ];
    // if (constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN) {
    //   this.campaignTypes.push({
    //     value: 'masterdataonly',
    //     incentiveType: null,
    //     label: 'Master data only '
    //   });
    // }

    this.lastUpdatedData = [
      {
        value: getDateRange('today'),
        label: 'Today'
      },
      {
        value: getDateRange('last 7 days'),
        label: 'Last 7 days'
      },
      {
        value: getDateRange('last 14 days'),
        label: 'Last 14 days'
      },
      {
        value: getDateRange('last 30 days'),
        label: 'Last 30 days'
      },
      {
        value: getDateRange('last 60 days'),
        label: 'Last 60 days'
      },
      {
        value: getDateRange('last 90 days'),
        label: 'Last 90 days'
      },
      {
        value: getDateRange('last month'),
        label: 'Last calendar month'
      },
      {
        value: getDateRange('this year'),
        label: 'This year'
      },
      {
        value: getDateRange('past year'),
        label: 'Last calendar year'
      },
      {
        value: 'All time',
        label: 'All time'
      }
    ];

    //this.filters['country'] = new Array();
    this.filtersCnt = 0;
    this.radioDropDownOptions = [];
    this.brands = [];

    this.optionRef = React.createRef();
    this.listfilter = React.createRef();

    this.roleForFilter = constant.dashboardRoleFilter;
    this.roleBasedStatus = constant.roleBasedStatus;

    this._handleNewCampaign = this._handleNewCampaign.bind(this);
    this._toggleOptions = this._toggleOptions.bind(this);
    this._getCountries = this._getCountries.bind(this);
    this._getBrands = this._getBrands.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this.getFiltersList = this.getFiltersList.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this._clearAllFilters = this._clearAllFilters.bind(this);
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this._setDefaultLastUpdatedFilter = this._setDefaultLastUpdatedFilter.bind(
      this
    );
    this._handleAdvanceSearchOpen = this._handleAdvanceSearchOpen.bind(this);
    this._setRoleBaseFilters = this._setRoleBaseFilters.bind(this); // -----
    this._toggleAdvFilter = this._toggleAdvFilter.bind(this);
    this._getCampaignWith = this._getCampaignWith.bind(this);
    this._getRequestTypeWith = this._getRequestTypeWith.bind(this);
    this._toggleFilters = this._toggleFilters.bind(this);
  }
  componentDidMount() {
    let { limit, pageNumber } = this.props;
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';
    this.country = [];

    this._setDefaultLastUpdatedFilter();
    this.props.setCampaignToEdit(true);

    this.showAdvanceSearch = this.props.showAdvanceSearch;

    //role based country and status filter setup ---
    this.radioDropDownOptions =
      this.props.authorizedUserData.group &&
      this.roleForFilter.includes(this.props.authorizedUserData.group)
        ? constant.adminStatusDropDownOptions
        : constant.statusDropDownOptions;
    this._setRoleBaseFilters();
    this.props.getBrandCountries();
    this.props.getBrands();
    this.props.getWebsiteTypes();
    this._getBrands();
    this._getWebsiteTypes();
    this.props.fetchCampaignsList(
      pageNumber,
      limit,
      this.sortKey,
      this.sortBy,
      this.body
    );
    this.sortDashboard = this.sortDashboard.bind(this);
  }
  _handleNewCampaign() {
    // NEW Campaign Route
  }
  _setRoleBaseFilters() {
    if (
      this.props.authorizedUserData &&
      this.roleForFilter.includes(this.props.authorizedUserData.group)
    ) {
      const role = this.props.authorizedUserData.group;
      this.body['status'] = this.roleBasedStatus[role]
        ? [this.roleBasedStatus[role]['status']]
        : '';
      this.filtersCnt++;
      this.body['role'] = role === 'admin' ? 'approver' : role;
      //get approver country
      if (
        this.props.authorizedUserData &&
        this.props.authorizedUserData.country &&
        this.props.authorizedUserData.country.length
      ) {
        this.country = getUserCountryList(
          this.props.authorizedUserData.country
        );
        let getCountriessplit = getSplitDashboardArray(this.country);
        this.filters['brandcountry'] = this.country;
        this.filtersCnt =
          this.filtersCnt + this.props.authorizedUserData.country.length;
        this.body['brandcountry'] = getCountriessplit['id'];
        this.setState({ brandcountry: this.country });
      }
      let rolebasestatus = this.roleBasedStatus[role]
        ? [
            {
              label: this.roleBasedStatus[role]['label'],
              value: this.roleBasedStatus[role]['status']
            }
          ]
        : '';

      this.setState({
        defaultFiltersEnabled: true,
        role,
        status: rolebasestatus
      });

      this.showRolelabels = this.roleBasedStatus[role]
        ? this.roleBasedStatus[role]['label']
        : '';

      this.filters['status'] = rolebasestatus;
    }
  }
  _handleNewCampaign() {
    // NEW Campaign Route
  }

  _handleAdvanceSearchOpen = status => {
    sessionStorage.setItem('advanceSearchOption', status);
  };

  _handleAdvanceSearch = ([campaignId, name, searchQuery = '']) => {
    let { limit, pageNumber } = this.props;

    if (campaignId === '' && name === '' && searchQuery === '') {
      this._setRoleBaseFilters();
    } else {
      this.body = {};
      this.filters = {};
      this.filtersCnt = 0;

      //removing default filters
      this.setState(state => {
        return {
          ...state,
          ...{ status: '' },
          ...{ brandcountry: '' },
          ...{ campaigntype: '' },
          ...{ lastUpdated: '' },
          ...{ defaultFiltersEnabled: false }
        };
      });
      this.body = {
        campaignId,
        name,
        searchQuery
      };
    }

    this.props.fetchCampaignsList(
      pageNumber,
      limit,
      this.sortKey,
      this.sortBy,
      this.body
    );
  };

  componentWillUnmount() {
    this.props.resetAppProps(false);
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.code + '-' + country._id, label: country.name };
      });
    }
  }
  _getBrands() {
    let { brands } = this.props;
    if (Object.keys(brands).length > 0) {
      return brands.map(function(brand) {
        return { value: brand.code + '-' + brand._id, label: brand.name };
      });
    }
  }

  _getWebsiteTypes() {
    let { websiteTypes } = this.props;
    if (websiteTypes && Object.keys(websiteTypes).length > 0) {
      return websiteTypes.map(function(website) {
        return { value: website._id, label: website.name };
      });
    }
  }

  _handleFocus(event) {
    event.target.select();
  }

  _handleDropDownValue(selectedOption, action) {
    let { limit, pageNumber } = this.props;
    let optionName = action && action.name;
    this.setState({ defaultFiltersEnabled: false });

    if (selectedOption.constructor === Array && selectedOption.length) {
      let sortedStatus = selectedOption.sort(sortArray('label'));
      this.filters[optionName] = sortedStatus;
      this.filtersCnt++;
      this.body[optionName] = selectedOption.flatMap(data => {
        return data.value.indexOf('-') >= 0
          ? data.value.split('-')[1]
          : data.value === 'All time'
          ? ''
          : data.value;
      });

      if (
        this.body[optionName] &&
        this.body[optionName].length &&
        (this.body[optionName].includes('Incentive samples') ||
          this.body[optionName].includes('Incentive competitions'))
      ) {
        let newCampaignTypeArr = [];
        this.body[optionName].forEach(e => {
          if (
            e === constant.campaignRequestTypes.INCENTIVE_COMPITITIONS ||
            e === constant.campaignRequestTypes.INCENTIVE_SAMPLE
          ) {
            newCampaignTypeArr.push('Incentive');
          } else {
            newCampaignTypeArr.push(e);
          }
        });
        this.body[optionName] = [...new Set(newCampaignTypeArr)];
      }

      if (optionName === 'campaigntype') {
        this.body['incentiveType'] = selectedOption.flatMap(data => {
          return data.incentiveType && data.incentiveType.indexOf('-') >= 0
            ? data.incentiveType.split('-')[1]
            : data.incentiveType && data.incentiveType === 'All time'
            ? ''
            : data.incentiveType;
        });
        let finalIncentiveType = [];
        this.body['incentiveType'] &&
          this.body['incentiveType'].forEach(e => {
            if (e) {
              finalIncentiveType.push(e);
            }
          });
        this.body['incentiveType'] = finalIncentiveType;
      }

      this.setState(state => {
        let optionvalue = {};
        optionvalue[optionName] = selectedOption;
        return { ...state, ...optionvalue };
      });
    } else if (
      selectedOption.constructor === Object &&
      Object.keys(selectedOption).length
    ) {
      this.filters[optionName] = [];
      this.filters[optionName].push(selectedOption);
      if (this.filtersCnt > 1) {
        this.filtersCnt++;
      }

      this.body[optionName] = [];
      if (selectedOption.value !== 'All time') {
        this.body[optionName].push(selectedOption.value);
      }
      if (selectedOption && selectedOption.incentiveType) {
        this.body['incentiveType'].push(selectedOption.incentiveType);
        this.body['incentiveType'] = new Set([...this.body['incentiveType']]);
      }

      this.setState(state => {
        let optionvalue = {};
        optionvalue[optionName] = selectedOption;
        return { ...state, ...optionvalue };
      });
    } else {
      if(optionName === 'showMyCampaigns'){
        if(selectedOption){
          const userData = this.props.authorizedUserData;
          this.body[optionName] = `${encodeURIComponent(userData.emailId)}|${
            userData.group
          }`;
          this.filters[optionName] = true;
          this.filtersCnt++;
        } else {
          this.filters[optionName] = false;
          this.filtersCnt = this.filtersCnt - 1;
          delete this.body[optionName]
        }
        this.setState(state => {
          let optionvalue = {};
          optionvalue[optionName] = selectedOption;
          return { ...state, ...optionvalue };
        });
      } else if(optionName === 'showLiveCampaigns') {
        if(selectedOption){
          this.body[optionName] = true//'ongoing'
          this.filters[optionName] = true//'ongoing';
          this.filtersCnt++;
        } else {
          this.filters[optionName] = false;
          this.filtersCnt = this.filtersCnt - 1;
          delete this.body[optionName]
        }
        this.setState(state => {
          let optionvalue = {};
          optionvalue[optionName] = selectedOption;
          return { ...state, ...optionvalue };
        });
      } else if (optionName !== 'lastUpdated') {
        this.body[optionName] = '';
        this.filters[optionName] = '';
        this.filtersCnt = this.filtersCnt - 1;
        this.setState(state => {
          let optionvalue = {};
          optionvalue[optionName] = '';
          return { ...state, ...optionvalue };
        });
      } else {
        this._setDefaultLastUpdatedFilter();
      }
    }

    this.props.fetchCampaignsList(
      1,
      limit,
      this.sortKey,
      this.sortBy,
      this.body
    );
  }

  sortDashboard(e) {
    let { limit, pageNumber } = this.props;
    let classname = e.target.className;
    let updatedClassName;
    if (classname.indexOf('--asc') >= 0) {
      this.sortBy = 'asc';
      updatedClassName = 'cw-sort cw-sort--desc';
    } else {
      this.sortBy = 'desc';
      updatedClassName = 'cw-sort cw-sort--asc';
    }
    this.setState({
      sortClass: updatedClassName
    });
    e.target.className = updatedClassName;
    this.sortKey = e.currentTarget.getAttribute('data-type');
    this.props.fetchCampaignsList(
      1,
      limit,
      this.sortKey,
      this.sortBy,
      this.body
    );
  }

  checkCampaignsExist = () => {
    return this.props.list.length ? true : false;
  };

  _toggleOptions(event) {
    if (this.optionRef.current.classList.contains('cw-hidden')) {
      this.optionRef.current.classList.remove('cw-hidden');
    } else {
      this.optionRef.current.classList.add('cw-hidden');
    }
  }

  _setDefaultLastUpdatedFilter() {
    //setting default last updated filter to last 30 days
    let getcurentMonthDateRange = getDateRange('last 30 days');
    this.defaultLastUpdatedValue = getcurentMonthDateRange;
    this.body['lastUpdated'] = [];
    this.filters['lastUpdated'] = [];
    this.filtersCnt++;
    this.body['lastUpdated'].push(getcurentMonthDateRange);
    this.filters['lastUpdated'].push({
      value: getcurentMonthDateRange,
      label: 'Last 30 days'
    });

    this.setState({
      lastUpdated: {
        value: getcurentMonthDateRange,
        label: 'Last 30 days'
      }
    });
  }

  _clearAllFilters(event) {
    event.preventDefault();
    let { limit, pageNumber } = this.props;
    let { campaignId, name, brand } = this.body;
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';
    this.filtersCnt = 0;

    this.setState(state => {
      return {
        ...state,
        ...{ status: '' },
        ...{ brands: '' },
        ...{ brandcountry: '' },
        ...{ campaigntype: '' },
        ...{ lastUpdated: '' },
        ...{ websiteTypes: '' },
        ...{ incentiveType: '' },
        ...{ defaultFiltersEnabled: false },
        ...{ campaignWith: '' },
        ...{ requestTypeWith: '' },
        ...{ showMyCampaigns: false },
        ...{ showLiveCampaigns: '' }
      };
    });
    Object.keys(this.filters).forEach(k => {
      if(k === 'showMyCampaigns' || k === 'showLiveCampaigns'){
        this.filters[k] = false
      } else {
        this.filters[k] = ''
      }
    });
    Object.keys(this.body).forEach(k => (this.body[k] = ''));
    if (campaignId === '' && name === '' && brand === '') {
      this._setDefaultLastUpdatedFilter();
    } else {
      this.body = { campaignId, name, brand };
    }

    this.props.fetchCampaignsList(
      1,
      limit,
      this.sortKey,
      this.sortBy,
      this.body
    );
  }

  removeFilter(value, type) {
    this.setState({ defaultFiltersEnabled: false });
    if (this.state[type]) {
      if (this.state[type].constructor === Array) {
        var typedata = [...this.state[type]];
        typedata.forEach((item, index) => {
          if (item.value === value) {
            typedata.splice(index, 1);
          }
        });
        let updatedData = [];
        updatedData[type] = typedata;
        this.setState(state => {
          return { ...state, ...updatedData };
        });
      }
      if (!this.state[type].length) {
        this.setState(state => {
          let data = [];
          data[type] = '';
          return { ...state, ...data };
        });
      }
    }

    if(type === 'showMyCampaigns') {
      this.setState({ showMyCampaigns: false });
      this.filters[type] = false;
    }

    if(type === 'showLiveCampaigns') {
      this.setState({ showLiveCampaigns: '' });
      this.filters[type] = false;
      this.filtersCnt -= 1
    }

    //remove from filters also
    if (this.filters[type]) {
      if (this.filters[type].constructor === Array) {
        this.filters[type].forEach((item, index) => {
          if (item.value === value) {
            this.filters[type].splice(index, 1);
          }
          // if(item.value ==='Incentive samples' || item.value ==='Incentive competitions'){
          //   this.filters[type].splice(index, 1);
          // }
        });
      }
    }

    this.filtersCnt = this.filtersCnt - 1;
    if (this.body.hasOwnProperty(type)) {
      if (this.body[type].constructor === Array) {
        let bodyValue = [...this.body[type]];
        this.body[type].forEach((item, index) => {
          if (value.indexOf('-') >= 0) {
            if (item === value.split('-')[1]) {
              bodyValue.splice(index, 1);
            }
          } else {
            if (value.constructor === Array) {
              if (value.indexOf(item) >= 0) {
                bodyValue = bodyValue.filter((data, index) => {
                  return data !== item;
                });
              }
            } else {
              if (item === value) {
                bodyValue.splice(index, 1);
              }
              if (
                value === constant.campaignRequestTypes.INCENTIVE_SAMPLE ||
                value === constant.campaignRequestTypes.INCENTIVE_COMPITITIONS
              ) {
                let incentiveType = [...this.body['incentiveType']];
                if (incentiveType && incentiveType.length === 2) {
                  if (
                    value === constant.campaignRequestTypes.INCENTIVE_SAMPLE
                  ) {
                    incentiveType = incentiveType.filter(
                      e => e != constant.campaignRequestTypes.PRODUCT_SAMPLE
                    );
                  } else if (
                    value ===
                    constant.campaignRequestTypes.INCENTIVE_COMPITITIONS
                  ) {
                    incentiveType = incentiveType.filter(
                      e => e != constant.campaignRequestTypes.COMPITITIONS
                    );
                  }
                } else if (incentiveType && incentiveType.length === 1) {
                  if (
                    value === constant.campaignRequestTypes.INCENTIVE_SAMPLE
                  ) {
                    incentiveType = incentiveType.filter(
                      e => e != constant.campaignRequestTypes.PRODUCT_SAMPLE
                    );
                  } else if (
                    value ===
                    constant.campaignRequestTypes.INCENTIVE_COMPITITIONS
                  ) {
                    incentiveType = incentiveType.filter(
                      e => e != constant.campaignRequestTypes.COMPITITIONS
                    );
                  }
                }
                if (incentiveType && incentiveType.length == 0) {
                  let IncentiveIndex = bodyValue.findIndex(
                    e => e === constant.campaignRequestTypes.INCENTIVE
                  );
                  if (IncentiveIndex > -1) {
                    bodyValue.splice(IncentiveIndex, 1);
                  }
                }
                this.body['incentiveType'] = incentiveType;
              }
            }
          }
        });
        this.body[type] = bodyValue;
      } else {
        if (this.body[type] === value) {
          delete this.body[type];
        }
      }

      if (type === 'lastUpdated' && !this.state['lastUpdated'].length) {
        this._setDefaultLastUpdatedFilter();
      }

      if (type === 'showMyCampaigns') {
        delete this.body[type];
      }
      
      this.props.fetchCampaignsList(
        this.props.pageNumber,
        this.props.limit,
        this.sortKey,
        this.sortBy,
        this.body
      );
    }
  }
  getFiltersList() {
    let orderedFilters = {};
    let unorderedfilters = this.filters;
    let filters;
    Object.keys(this.filters)
      .sort()
      .forEach(function(key) {
        orderedFilters[key] = unorderedfilters[key];
      });
    if (
      Object.keys(orderedFilters).length > 0 &&
      orderedFilters.constructor === Object
    ) {
      filters = Object.keys(orderedFilters).map((keyName, i) => {
        if (orderedFilters[keyName].length) {
          return orderedFilters[keyName].map((item, indx) => {
            return (
              <div key={indx} className="cw-dashboard-filters">
                <div className="cw-dashboard-filters-label">
                  {keyName === 'lastUpdated'
                    ? orderedFilters[keyName][indx]['value']
                    : orderedFilters[keyName][indx]['label']}
                </div>

                {/* remove cross sign if LastUpdated filter is selected as defaultvalue */}
                {keyName === 'lastUpdated' &&
                orderedFilters[keyName][indx]['value'] ===
                  this.defaultLastUpdatedValue ? null : (
                  <div
                    className="cw-dashboard-filters-remove"
                    onClick={() =>
                      this.removeFilter(
                        orderedFilters[keyName][indx]['value'],
                        keyName
                      )
                    }
                  >
                    <img src={DeleteIcon} alt="Remove filter icon" />
                  </div>
                )}
              </div>
            );
          });
        } else if(typeof orderedFilters[keyName] === 'boolean'){
          if(orderedFilters[keyName]) {
            // this.filtersCnt++;
            return (
            <div className="cw-dashboard-filters">
                  <div className="cw-dashboard-filters-label">
                    {keyName === 'showMyCampaigns' ? 'My campaigns' : 'Live campaigns'}
                  </div>
                  <div
                    className="cw-dashboard-filters-remove"
                    onClick={() =>
                      this.removeFilter(
                        orderedFilters[keyName],
                        keyName
                      )
                    }
                  >
                    <img src={DeleteIcon} alt="Remove filter icon" />
                  </div>
            </div>
            ) 
          };
        } else {
          return '';
        }
      });
      return filters;
    }
  }

  onPageChange = pageNumber => {
    this.props.fetchCampaignsList(
      pageNumber,
      this.props.limit,
      this.sortKey,
      this.sortBy,
      this.body
    );
  };

  /**
   * Advance campaign filter handler
   */
  _toggleAdvFilter() {
    this.setState(s => ({ isAdvFilterOpened: !s.isAdvFilterOpened }));
  }
  _getCampaignWith() {

    const multiChannelConsets = []
    if(this.props.campaignConfig && this.props.campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE){
      multiChannelConsets.push({
        label: 'Multi-channel consent',
        value: 'multiChannelConsetns'
      })
    }

    this.campaignWith = [
      {
        label: 'Questions & answers',
        value: 'questionsAndAnswers'
      },
      {
        label: 'New questions',
        value: 'newQuestions'
      },
      {
        label: 'New answers',
        value: 'newAnswers'
      },
      {
        label: 'New questions OR answers',
        value: 'newQuestionsOrAnswers'
      },
      {
        label: 'New enrichment questions',
        value: 'newEnrichmentQuestions'
      },
      {
        label: 'New operational questions',
        value: 'newOperationalQuestions'
      },
      // {
      //   label: 'Form widget requests',
      //   value: 'formWidgetRequests'
      // },
      {
        label: 'Infra setup failure',
        value: 'infraSetupFailure'
      },
      {
        label: 'Infra setup success',
        value: 'infraSetupSuccess'
      },
      {
        label: 'Migration campaigns',
        value: 'migrationCampaigns'
      },
      {
        label: 'Not using enterprise consents',
        value: 'notEnterpriseConsents'
      },
      {
        label: 'Always on campaigns',
        value: 'alwaysOnCampaign'
      },
      {
        label: 'Rejected atleast once',
        value: 'rejectedAtleastOnce'
      },
      {
        label: 'Edited atleast once',
        value: 'editedAtleastOnce'
      },
      {
        label: 'Edit permissions restricted',
        value: 'editPermissionRestricted'
      },
      {
        label: 'Created using clone',
        value: 'clonedCampaigns'
      },
      {
        label: 'Additional Opt-ins',
        value: 'additionalOptins'
      },
      {
        label: 'Brand consent',
        value: 'brandConsents'
      },
      ...multiChannelConsets
    ];
    return this.campaignWith;
  }
  _getRequestTypeWith() {
    return [
      {
        label: 'Master data only',
        value: 'masterDataOnly'
      },
      {
        label: 'Sign up form (Form widgets)',
        value: 'formWidgetRequests'
      },
      {
        label: 'Sign up form (AEM, others)',
        value: 'aemForms'
      }
    ];
  }
  _toggleFilters() {
    this.setState(state => ({showFilters: !state.showFilters}));
  }

  handleShowMyCampaignsOnChange = () => {
    this._handleDropDownValue(!this.state.showMyCampaigns, {
      name: 'showMyCampaigns'
    });
  };

  handleShowLiveCampaignsOnChange = () => {
    this._handleDropDownValue(!this.state.showLiveCampaigns, {
      name: 'showLiveCampaigns'
    });
  };

  render() {
    let {
      asyncInProgress,
      deletedCampaign,
      showLoader,
      authorizedUserData,
      recordShowStart,
      recordShowto,
      pageNumber,
      totalCount,
      limit,
      list,
      campaignConfig
    } = this.props;

    const { role, sortClass, defaultFiltersEnabled } = this.state;

    return (
      <>
        <div className="col-sm-12">
          <div className="cw-section cw-dashboard ml-20">
            <NotificationMessage
              showcomponent={this.props.notificationMessage}
              message={this.props.notificationMessage}
              type={this.props.notificationType}
              link={this.props.notificationLink}
              campaignname={this.props.notificationCampaign}
            />
            <div
              className={`alert alert-warning${
                deletedCampaign !== null ? '' : ' d-none'
              }`}
              role="alert"
            >
              <p>
                <strong>
                  {deletedCampaign ? deletedCampaign : 'The campaign'} has been
                  deleted.
                </strong>
              </p>
            </div>
            <div className="cw-listing--header mb-20 d-flex align-items-center justify-content-between">
              <h2 className="cw-heading--secondary mb-20 mr-4 d-inline-block">
                Data capture campaigns
              </h2>
              {this.props.userrole === constant.roles.REQUESTOR ||
              this.props.userrole === constant.roles.APPROVER ? (
                <NavLink
                  className="btn btn-primary btn-newcampaign"
                  to={'/campaign-wizard/newcampaign'}
                  style={{ width: '20%' }}
                >
                  New campaign
                </NavLink>
              ) : null}
            </div>
            {/* Advance Search Section */}
            <AdvanceSearch
              searchEvent={this._handleAdvanceSearch}
              brands={this.props.brands.slice(0, 100)}
              showAdvanceSearch={sessionStorage.getItem('advanceSearchOption')}
              handleAdvanceSearchOpen={this._handleAdvanceSearchOpen}
              campaignConfig={campaignConfig}
              toggleFilters={this._toggleFilters}
            />

            {/* Role based filter */}
            <div className="row mt-20 cw-dashboard__filters">
              {this.state.showFilters && (
                <div className="d-flex align-items-center col-sm-12">
                  {/* Country dropdown */}
                  <div className="filter-item mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter'}
                        menuIsOpen={false}
                        name={'brandcountry'}
                        label={'Country'}
                        hideSelectedOptions={false}
                        placeholder={'Country'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.brandcountry}
                        onChange={this._handleDropDownValue}
                        options={this._getCountries()}
                        isClearable={false}
                      />
                    </div>
                  </div>

                  {/* Brand Filter */}
                  <div className="filter-item mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter'}
                        menuIsOpen={true}
                        name={'brands'}
                        label={'Brand'}
                        hideSelectedOptions={false}
                        placeholder={'Brand'}
                        value={this.state.brands}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        onChange={this._handleDropDownValue}
                        options={this._getBrands()}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  {/* Campaign Type dropdown */}
                  <div className="filter-item mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter'}
                        name={'campaigntype'}
                        label={'Request type'}
                        hideSelectedOptions={false}
                        placeholder={'Request type'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.campaigntype}
                        onChange={this._handleDropDownValue}
                        options={this.campaignTypes}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  {/* WebsiteType Filter */}
                  <div className="filter-item mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter'}
                        menuIsOpen={true}
                        name={'websiteTypes'}
                        label={'Website Type'}
                        hideSelectedOptions={false}
                        placeholder={'Website Type'}
                        value={this.state.websiteTypes}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        onChange={this._handleDropDownValue}
                        options={this._getWebsiteTypes()}
                        isClearable={false}
                      />
                    </div>
                  </div>

                  {/* Status Dropdown */}

                  <div className="filter-item mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter'}
                        name={'status'}
                        label={'Status'}
                        hideSelectedOptions={false}
                        placeholder={'Status'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.status}
                        onChange={this._handleDropDownValue}
                        options={this.radioDropDownOptions}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  {/* */}

                  {/* Last Updated Dropdown */}
                  <div className="filter-item">
                    <div className="form-group mb-0">
                      <RadioDropDown
                        isMulti={false}
                        className={'cw-dashboard-filter last-updated-filter'}
                        name={'lastUpdated'}
                        label={'Last updated'}
                        hideSelectedOptions={false}
                        placeholder={'Last Updated'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.lastUpdated}
                        onChange={this._handleDropDownValue}
                        options={this.lastUpdatedData}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </div>
              )}
              
              {/* Advance campaign filter toggle */}
              {constant.featureFlags.ENABLE_NEW_QNA_FOR_SAAS ? this.state.showFilters && (
                <div className="cw-dashboard__filters--advance col-sm-12">
                  <button
                    className="cw-dashboard__filters--advance-toggle advFilter"
                    onClick={this._toggleAdvFilter}
                  >
                    {!this.state.isAdvFilterOpened ? (
                      <span className="dark-text toggle-text">Show more</span>
                    ) : (
                      <span className="dark-text toggle-text">Show less</span>
                    )}
                    <i
                      className={`toggle-arrow fa ${
                        !this.state.isAdvFilterOpened
                          ? 'fa-chevron-down'
                          : 'fa-chevron-up'
                      }`}
                    ></i>
                  </button>
                  <div
                    className={
                      !this.state.isAdvFilterOpened ? 'hide' : 'show d-flex'
                    }
                  >
                    <div className="col-sm-3">
                      <div className="form-group">
                        <CheckboxDropDown
                          isMulti={true}
                          className={
                            'cw-dashboard-filter custom-tag-value-advance-filter'
                          }
                          menuIsOpen={true}
                          name={'campaignWith'}
                          label={'Show campaigns with'}
                          hideSelectedOptions={false}
                          placeholder={'Show campaigns with'}
                          value={this.state.campaignWith}
                          isDisabled={asyncInProgress}
                          isLoading={asyncInProgress}
                          onChange={this._handleDropDownValue}
                          options={this._getCampaignWith()}
                          isClearable={false}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 pr-0">
                      <div className="form-group">
                        <CheckboxDropDown
                          isMulti={true}
                          className={
                            'cw-dashboard-filter custom-tag-value-advance-filter'
                          }
                          menuIsOpen={true}
                          name={'requestTypeWith'}
                          label={'Request type with'}
                          hideSelectedOptions={false}
                          placeholder={'Request type with'}
                          value={this.state.requestTypeWith}
                          isDisabled={asyncInProgress}
                          isLoading={asyncInProgress}
                          onChange={this._handleDropDownValue}
                          options={this._getRequestTypeWith()}
                          isClearable={false}
                        />
                      </div>
                    </div>
                    <ReactTooltip
                      id="mainFilterToolTip"
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-toolip custom-cw-toolip"
                    />
                    <span
                      className="cw-icon cw-icon--help ml-2 mt-2"
                      data-tip={`The ability to track which requests are for sign up forms widgets vs master data (for offline ingestion or custom forms) is available only from September 2023, when a more tailored journey was introduced for master data requests`}
                      currentitem="false"
                      data-for="mainFilterToolTip"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            
            <div className="d-flex justify-content-between align-items-center mb-20">
              <div>
                {this.filtersCnt ? (
                  <>
                    {this.getFiltersList()}
                    {console.log(this.filtersCnt, this.getFiltersList())}
                    {this.filtersCnt >= 2 ? (
                      <div className="cw-dashboard-filters clearall">
                        <div
                          className="cw-dashboard-filters-label clearalllabel"
                          onClick={this._clearAllFilters}
                        >
                          Clear all
                        </div>
                      </div>
                    ) : null}
                  </>
                  ) : null}
                </div>
                <div >
                  <div className="d-flex">
                    <CheckBoxInput
                      id="showMyCampaigns"
                      value={this.state.showMyCampaigns}
                      label="My campaigns"
                      onChange={this.handleShowMyCampaignsOnChange}
                      className={classNames("email-dashboard-checkbox mr-3")}
                      labelClass="email-dashboard-checkbox__label"
                    />
                    <CheckBoxInput
                      id="showLiveCampaigns"
                      value={this.state.showLiveCampaigns}
                      label="Live campaigns"
                      onChange={this.handleShowLiveCampaignsOnChange}
                      className={classNames("email-dashboard-checkbox")}
                      labelClass="email-dashboard-checkbox__label"
                    />
                  </div>
                </div>
              </div>

            <CampaignList
              showLoader={showLoader}
              authorizedUserData={authorizedUserData}
              recordShowStart={recordShowStart}
              recordShowto={recordShowto}
              pageNumber={pageNumber}
              totalCount={totalCount}
              limit={limit}
              sortDashboard={this.sortDashboard}
              onPageChange={this.onPageChange}
              checkCampaignsExist={this.checkCampaignsExist}
              role={role}
              sortClass={sortClass}
              list={list}
              asyncInProgress={asyncInProgress}
              defaultFiltersEnabled={defaultFiltersEnabled}
            />
          </div>
        </div>
      </>
    );
  }
  // }
  // }
}

const mapStateToProps = state => ({
  authorizedUserData: state.authorized.authorizedUserData,
  showLoader: state.dashboard.showLoader,
  countries: state.dashboard.countries,
  brands: state.dashboard.brands,
  list: state.dashboard.campaigns,
  asyncInProgress: state.dashboard.asyncInProgress,
  totalCount: state.dashboard.totalCount,
  recordShowStart: state.dashboard.recordShowStart,
  recordShowto: state.dashboard.recordShowto,
  pageNumber: state.dashboard.pageNumber,
  limit: state.dashboard.limit,
  deletedCampaign: state.app.deletedCampaign,
  serviceError: state.dashboard.serviceError,
  serviceErrorStatus: state.dashboard.serviceErrorStatus,
  notificationMessage: state.dashboard.notificationMessage,
  notificationLink: state.dashboard.notificationLink,
  notificationType: state.dashboard.notificationType,
  notificationCampaign: state.dashboard.notificationCampaign,
  campaign: state.overview.campaign,
  campaignDetails: state.overview.campaignDetails,
  autogenerate: state.dashboard.autogenerate,
  websiteTypes: state.dashboard.websiteTypes,
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  fetchCampaignsList,
  getBrandCountries,
  getBrands,
  getWebsiteTypes,
  resetAppProps,
  setCampaignToEdit
})(Dashboard);
