import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import HistoryComponent from '../../components/history';
import { getSmsOverview } from '../../actions/communications/communicationOverview';
import { fetchHistoryLogs } from '../../actions/history';
import { openFeedbackForm } from '../../actions/feedbackForm';
import { setNotificationMessage } from 'actions/dashboard';
import NotFound from 'components/NotFound';
import SMSSummaryCards from 'components/organisms/EmailSummaryCards/SMSSummaryCards';
import CommunicationsOverviewTables from 'components/organisms/CommunicationsOverviewTables';
import Toolbar from './Toolbar';
import print_icon from '../../images/print_icon.png';
import feedback_icon from '../../images/feedback_icon.png';
import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import { getMobilePreviewDates } from 'utilities/util';
import {
  getSMSReviewData,
  checkSMSCampaignEditRights,
  userHasAwaitingPurchaseOrderPermission,
  userHasResubmitPermission
} from 'selectors';
import {
  deleteSMSCampaignByUid,
  downloadSMSCostEstimate,
  downloadSMSPurchaseOrder
} from 'services/communicationService';
import DeleteConfirmationModal from './DeleteSmsCampaignModal';
import Alert from 'components/Alert';
import constants from 'constants/config';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import Loader from 'components/Loader';
import { get } from 'lodash';
import DownloadIcon from '../../images/downloadIcon';
import RequestMoreInfoIcon from '../../images/requestMoreInfoIcon';
import SmsPurchaseOrderModel from 'components/organisms/SmsPurchaseOrderModel';
import SmsEditModel from 'components/organisms/SmsEditModel';
import DownloadLinkBtn from 'components/molecules/DownloadLinkBtn';

class CommunicationsOverview extends Component {
  state = {
    uid: this.props.match.params.id,
    isDeleteModalOpen: false,
    purchaseOrderModelOpen: false,
    editModelOpen: false,
    isDownloading: false,
    message: '',
    isError: false,
  };
  componentDidMount() {
    this.props.fetchHistoryLogs(this.state.uid);
    this.props.getSmsOverview(this.state.uid);
  }

  handleOnOpenFeedback = () => {
    this.props.openFeedbackForm({
      campaignUid: this.props.match.params.id,
      userId: this.props.authorizedUserData._id,
      defaultCampaignRequest: 'SMS',
      showCampaignRequest: false
    });
  };

  handleDelete = () => {
    this.setState({ isDeleteModalOpen: true });
  };
  handleDeleteModal = value => {
    this.setState({ isDeleteModalOpen: value });
  };

  deleteSMSCampaign = () => {
    let reqbody = {
      uid: this.state.uid,
      userEmail: this.props.userEmail,
      userName: this.props.userName
    };
    let campaignName =
      (this.props.smsDetailsOverview && this.props.smsDetailsOverview.name) ||
      'Untitled';
    deleteSMSCampaignByUid(reqbody)
      .then(result => {
        this.props.setNotificationMessage(
          `${campaignName} has been deleted.`,
          undefined,
          'warning'
        );
        this.props.history.push(`/campaign-wizard/smsdashboard`);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  goToFirstPage = () => {
    const uid = this.state.uid;
    this.props.history.push(
      `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.BRAND_DETAILS}`
    );
  };

  redirect = (path, lastUpdatedScreen) => {
    if (
      [`${constants.SMS_ROUTES.NEW_CAMPAIGN_SUMMARY}`].includes(
        lastUpdatedScreen
      )
    ) {
      this.props.history.push(path + constants.SMS_ROUTES.CAMPAIGN_SUMMARY);
    } else {
      this.props.history.push(path + lastUpdatedScreen);
    }
  };

  resumeWhereYouLeft = () => {
    const uid = this.state.uid;
    const path = `${constants.SMS_BASE_ROUTE}/${uid}/`;

    const { lastUpdatedScreen, status } = this.props.smsDetailsOverview;
    const isCampaignRejected = status === constants.SMS_STATUS.REJECTED;

    if (lastUpdatedScreen && !isCampaignRejected) {
      this.redirect(path, lastUpdatedScreen);
    } else {
      this.props.history.push(path + constants.SMS_ROUTES.BRAND_DETAILS);
    }
  };

  handleDownloadCostEstimateClick = (link, userPermission, setIsDownloading) => {
    if (userPermission) {
      const [bucketName, ...fileNameParts] = link
        .replace('gs://', '')
        .split('/');
      const fileName = fileNameParts.join('/');
      const uid = this.state.uid;
      if(setIsDownloading){
        setIsDownloading(true)
      }
      //this.setState({ isDownloading: true });
      downloadSMSCostEstimate(uid)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          if(setIsDownloading){
            setIsDownloading(false)
          }
          //this.setState({ isDownloading: false });
        })
        .catch(error => {
          if(setIsDownloading){
            setIsDownloading(false)
          }
          //this.setState({ isDownloading: false });
          console.log('error: ', error);
        });
      this.setState({
        message: '',
        isError: false
      });
    } else {
      this.setState({
        message: constants.DOWNLOAD_COST_ESTIMATE_ERR_MSG,
        isError: true
      });
    }
  };

  handleDownloadPOClick = (link, userPermission, setIsDownloading) => {
    if (userPermission) {
      const [bucketName, ...fileNameParts] = link
        .replace('gs://', '')
        .split('/');
      const fileName = fileNameParts.join('/');
      const uid = this.state.uid;
      if(setIsDownloading){
        setIsDownloading(true)
      }
      //this.setState({ isDownloading: true });
      downloadSMSPurchaseOrder(uid)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          if(setIsDownloading){
            setIsDownloading(false)
          }
          //this.setState({ isDownloading: false });
        })
        .catch(error => {
          if(setIsDownloading){
            setIsDownloading(false)
          }
          //this.setState({ isDownloading: false });
          console.log('error: ', error);
        });
    }
  };

  handleEditModelClick = () => {
    this.setState({ editModelOpen: true });
  };

  closeEditModelClick = () => {
    this.setState({ editModelOpen: false });
  };

  handlePurchaseOrderModelClick = userPermission => {
    if (userPermission) {
      this.setState({
        purchaseOrderModelOpen: true,
        message: '',
        isError: false
      });
    } else {
      this.setState({
        message: constants.UPLOAD_PO_ERR_MSG,
        isError: true
      });
    }
  };

  closePurchaseOrderModelClick = () => {
    this.setState({ purchaseOrderModelOpen: false });
    this.props.getSmsOverview(this.state.uid);
    this.props.fetchHistoryLogs(this.state.uid);
  };

  closePoModelClick = () => {
    this.setState({ purchaseOrderModelOpen: false });
  };

  downloadTempItem = (setIsDownloading) => {
    setIsDownloading(true)
    setTimeout(() => {
      setIsDownloading(false);
    }, 2000)
  }

  render() {
    const {
      name,
      status,
      rejectComments,
      requestInfoText,
      nmiAssignedToRequestor,
      requestorsEmail,
      emailCollaborators = {},
      shareCostEstimate = {},
      sharePurchaseOrder = {},
      isEdited = false
    } = this.props.smsDetailsOverview;

    const showPurchaseOrderCta =
      constants.SMS_STATUS.AWAITING_PURCHASE_ORDER === status;

    const isDraft =
      String(status) === '1' ||
      String(status) === '5' ||
      (nmiAssignedToRequestor === undefined &&
        String(status) === constants.SMS_STATUS.AWAITING_INFO) ||
      (String(status) === '8' && nmiAssignedToRequestor === true);
    const isRejected =
      String(status) === constants.SMS_STATUS.REJECTED ||
      (nmiAssignedToRequestor === undefined &&
        String(status) === constants.SMS_STATUS.AWAITING_INFO) ||
      (String(status) === constants.SMS_STATUS.AWAITING_INFO &&
        nmiAssignedToRequestor === true);

    let mobilePreviewData = getSMSReviewData(
      this.props.smsDetailsOverview,
      getMobilePreviewDates(this.props.smsDetailsOverview)
    );

    const userHasEditRights = checkSMSCampaignEditRights(
      this.props.smsDetailsOverview,
      this.props.authorizedUserData
    );

    const currentUser = {
      email: get(this.props.authorizedUserData, 'emailId', ''),
      group: get(this.props.authorizedUserData, 'group', '')
    };

    const shareCostAdditionalComments =
      shareCostEstimate?.additionalcomments || '';

    const shareCostGcsPath = shareCostEstimate?.costFile?.gcsPath || '';
    const sharePurchaseOrderPath = sharePurchaseOrder?.poFile?.gcsPath || '';

    const awaitingPurchaseOrderUserPermission = userHasAwaitingPurchaseOrderPermission(
      requestorsEmail,
      currentUser,
      emailCollaborators
    );

    const userClickPermission =
      awaitingPurchaseOrderUserPermission &&
      checkSMSCampaignEditRights(
        this.props && this.props.smsDetailsOverview,
        this.props.authorizedUserData
      );

    if (this.props.smsServiceError === 404) {
      return <NotFound />;
    } else if (this.props.isLoading || this.state.isDownloading) {
      return <Loader />;
    } else if (this.props.smsServiceError === null) {
      return (
        <>
          <SmsPurchaseOrderModel
            modalIsOpen={this.state.purchaseOrderModelOpen}
            closeModal={this.closePurchaseOrderModelClick}
            closePoModelClick={this.closePoModelClick}
            smsDetails={this.props && this.props.smsDetailsOverview}
            pathUid={this.state.uid}
          />
          <SmsEditModel
            modalIsOpen={this.state.editModelOpen}
            closeModal={this.closeEditModelClick}
            smsDetails={this.props && this.props.smsDetailsOverview}
            pathUid={this.state.uid}
          />

          <div className="skypink ml-5 mr-5">
            <div className="cw-section cw-overview">
              <div className="draft_button_section_main">
                <Alert
                  type={'warning'}
                  message={rejectComments ? rejectComments : ''}
                  alertType={'rejectcomment'}
                  showComponent={status === '5'}
                />
                {status === constants.SMS_STATUS.AWAITING_PURCHASE_ORDER ? (
                  <div className={`row email-summary-font mt-4`}>
                    <div className="col-sm-12">
                      <div
                        className={`alert alert-warning`}
                        role="alert"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {constants.SMS_STATUS.AWAITING_PURCHASE_ORDER ===
                        status ? (
                          <>
                            ACS team has shared a cost estimate (download it{' '}
                            <DownloadLinkBtn onClick={(e, setDWloader) => {
                                e.preventDefault();
                                this.handleDownloadCostEstimateClick(
                                  shareCostGcsPath,
                                  userClickPermission,
                                  setDWloader
                                );
                              }} label="here" loaderClass="alert-link-loader" />
                            ). Please create a Purchase Order in Coupa and
                            upload it to your campaign.{' '}
                            {shareCostAdditionalComments && (<><br />ACS team comments&#58; {shareCostAdditionalComments}</>)}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <Alert
                  alertType={'saasWarningAlertMessageWithIcon'}
                  textMessage={this.state.message}
                  showComponent={this.state.isError}
                  closable={true}
                  onClose={() => {
                    this.setState({ isError: false });
                  }}
                />
                <Alert
                  type={'warning'}
                  className={'mt-4'}
                  message={requestInfoText ? requestInfoText : ''}
                  alertType={'requestMoreInfo'}
                  showComponent={status === '8'}
                  title={
                    nmiAssignedToRequestor
                      ? `ACS team has requested more from requestor  `
                      : `ACS team is awaiting more information internally - there is no next step on requestor `
                  }
                />
                <PageHeader
                  heading={name || 'Untitled'}
                  status={status}
                  subHeading={
                    isDraft ? 'Continue creating your campaign' : undefined
                  }
                  review
                />
                <div className="d-flex justify-content-between">
                  <div className="flex-grow-1 cw-print-hide">
                    <Toolbar>
                      {!isDraft && (
                        <Toolbar.Item
                          icon={print_icon}
                          name="Print"
                          onClick={() => window.print()}
                        />
                      )}
                      {constants.SMS_STATUS.AWAITING_PURCHASE_ORDER ===
                      status ? (
                        <Toolbar.DownloadItem
                          svgIcon={DownloadIcon}
                          name="Download cost estimate"
                          onClick={(e, setIsDownloading) => {
                            e.preventDefault();
                            this.handleDownloadCostEstimateClick(
                              shareCostGcsPath,
                              userClickPermission,
                              setIsDownloading
                            );
                          }}
                        />
                      ) : null}
                      {showPurchaseOrderCta ? (
                        <Toolbar.Item
                          svgIcon={RequestMoreInfoIcon}
                          // fontIcon="fas fa-trash toolbar-component__icon w-35"
                          name="Send purchase order to ACS"
                          onClick={() =>
                            this.handlePurchaseOrderModelClick(
                              userClickPermission
                            )
                          }
                        />
                      ) : null}
                      {(isDraft || isRejected) && userHasEditRights ? (
                        <Toolbar.Item
                          icon={feedback_icon}
                          fontIcon="fas fa-trash toolbar-component__icon w-35"
                          name="Delete"
                          onClick={() => this.handleDelete()}
                        />
                      ) : null}
                      <Toolbar.Item
                        icon={feedback_icon}
                        name="Feedback"
                        onClick={() => this.handleOnOpenFeedback()}
                      />
                    </Toolbar>
                  </div>
                  {showPurchaseOrderCta ? (
                    <div className="d-flex flex-column">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.handlePurchaseOrderModelClick(
                            userClickPermission
                          )
                        }
                      >
                        Send purchase order to ACS
                      </button>

                      {userHasResubmitPermission(requestorsEmail, currentUser, emailCollaborators) && 
                        <button
                          className="btn btn btn-outline-secondary mt-10"
                          onClick={this.handleEditModelClick}
                        >
                          Edit and resubmit campaign
                        </button>
                      }
                    </div>
                  ) : null}
                </div>
              </div>
              {status === constants.SMS_STATUS.AWAITING_FOR_APPROVAL ? (
                <div className="mb-30">
                  <EmailApproverInfo
                    emailBriefing={this.props && this.props.smsDetailsOverview}
                    userData={this.props.authorizedUserData}
                  />
                </div>
              ) : null}
              {(isEdited || isRejected) && userHasEditRights && (
                <div className="d-flex">
                  <div className="continue-section-empty"></div>
                  <div className="continue-camp-btn-container">
                    <button
                      className="btn btn-primary w-100"
                      onClick={this.goToFirstPage}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}

              {isDraft && !isEdited && !isRejected && userHasEditRights && (
                <div className="SMS-continue-camp-btn-container">
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-primary resume-email-camp"
                      onClick={this.resumeWhereYouLeft}
                    >
                      Resume where you left
                    </button>
                    <button
                      className="btn btn-outline-secondary mt-10"
                      onClick={this.goToFirstPage}
                    >
                      Start over
                    </button>
                  </div>
                </div>
              )}

              <Layout
                className="mt-30"
                sidebar={
                  <MobileDevicePreview
                    className="mobile-preview-overview"
                    data={mobilePreviewData}
                    alignHeight={isDraft || showPurchaseOrderCta ? false : true}
                    campaign={this.props.smsDetailsOverview}
                  />
                }
              >
                <SMSSummaryCards smsCampaign={this.props.smsDetailsOverview} />
                <CommunicationsOverviewTables
                  editable={false}
                  smsCampaign={this.props.smsDetailsOverview}
                  downloadTrigger={(d1, setDWloader) => {
                    this.handleDownloadCostEstimateClick(
                      shareCostGcsPath,
                      userClickPermission,
                      setDWloader
                    );
                  }}
                  downloadPOTrigger={(d2, setDWloader) => {
                    this.handleDownloadPOClick(
                      sharePurchaseOrderPath,
                      userClickPermission,
                      setDWloader
                    );
                  }}
                />
                <div className="overview-page-history mt-20">
                  {this.props.historyLogs && this.props.historyLogs.length ? (
                    <HistoryComponent
                      historyLogs={this.props.historyLogs}
                      isEmailBrief={true}
                    />
                  ) : null}
                </div>
              </Layout>
            </div>
            <DeleteConfirmationModal
              isOpen={this.state.isDeleteModalOpen}
              closeModal={this.handleDeleteModal}
              deleteCampaign={this.deleteSMSCampaign}
              allowDelete={isDraft || isRejected}
            />
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = state => ({
  smsDetailsOverview: state.communicationOverview.smsDetailsOverview,
  isLoading: state.communicationOverview.isLoading,
  historyLogs: state.historyLogs,
  smsServiceError: state.communicationOverview.smsServiceError
});

export default connect(mapStateToProps, {
  getSmsOverview,
  fetchHistoryLogs,
  openFeedbackForm,
  setNotificationMessage
})(CommunicationsOverview);
