import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Alert from '../../Alert';
import Select from 'react-select';
import constant from '../../../constants';
import { epsilonMarket, isConsentFieldEditable } from '../../../selectors';
import NewStarIcon from '../../../images/NewStar.svg';
import ErrorIcon from 'components/atoms/ErrorIcon/ErrorIcon';
import ConsentStatement from '../ConsentStatement/ConsentStatement';
const { consentTypes } = constant;

const incentiveNonMandatoryConsents = [
  consentTypes.unileverEmailConsent,
  consentTypes.unileverSMSConsent,
  consentTypes.brandSMSConsent,
  consentTypes.brandEmailConsent,
  consentTypes.unileverEmailOnlyConsent,
  consentTypes.unileverSMSOnlyConsent,
  consentTypes.unileverOnlineOnlyConsent,
  consentTypes.unileverWhatsAppConsent,
  consentTypes.brandWhatsAppConsent,
  consentTypes.unileverWhatsAppOnlyConsent,
  consentTypes.multiChannelUnileverConsent
];

export default class ConsentField extends Component {
  frequency = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Annually' }
  ];

  handleStatement = (e, index) => {
    const { elem, updateConsent } = this.props;
    let statementChecked = e.target.checked;
    if (statementChecked) {
      updateConsent(
        {
          ...elem,
          consentType: 'implicit'
        },
        index
      );
    }
  };

  handleStandaloneStatement = (e, index) => {
    const { elem, updateConsent } = this.props;
    let statementChecked = e.target.checked;
    if (statementChecked) {
      updateConsent(
        {
          ...elem,
          consentType: 'implicitStandalone'
        },
        index
      );
    }
  };

  handleMandatoryToggle = (e, index) => {
    const { elem, updateConsent } = this.props;
    let mandatoryChecked = e.target.checked;
    updateConsent(
      {
        ...elem,
        consentType: mandatoryChecked ? 'mandatory' : 'optional'
      },
      index
    );
  };

  _handleInputOnBlur = event => {
    if (event.target.dataset.customError === 'true') {
      // Email Format Validtion
      let regexPattern;
      regexPattern = constant.regex[event.target.dataset.customErrorType];
      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.id + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`replyToEmailAdderess`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`replyToEmailAdderess`)
            .classList.remove('cw-error-focus');
      } else {
        if (event.target.value.trim() === '') {
          document
            .querySelector('[data-custom-msg=' + event.target.id + ']')
            .classList.add('cw-hidden');
        } else {
          document
            .querySelector('[data-custom-msg=' + event.target.id + ']')
            .classList.remove('cw-hidden');
        }
      }
    }
  };

  handleMandatoryRadio = (e, index) => {
    const { elem, updateConsent } = this.props;
    let mandatoryChecked = e.target.checked;
    if (mandatoryChecked) {
      updateConsent(
        {
          ...elem,
          consentType:
            elem.optInType === 'unileverOnlineOnlyConsent' ||
            (this.props.campaignType === 'Incentive' &&
              incentiveNonMandatoryConsents.includes(elem.optInType))
              ? 'optional'
              : 'mandatory'
        },
        index
      );
    }
  };

  _handleMandatoryError = (e, index, optInType) => {
    const { elem, updateConsent } = this.props;
    let errorMessage = e.target.value;

    document.getElementById(`consent_mandatoryMessage_${optInType}`) &&
      document
        .getElementById(`consent_mandatoryMessage_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`errorMessage_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`errorMessage_${optInType}`)
        .classList.remove('cw-error-focus');

    updateConsent(
      {
        ...elem,
        mandatoryMessage: errorMessage
      },
      index
    );
  };

  onConsentStatementChange = defaultCopy => {
    const { elem, index, updateConsent } = this.props;

    updateConsent(
      {
        ...elem,
        defaultCopy
      },
      index
    );
  };

  _handleFrequency = (e, index, optInType) => {
    const { elem, updateConsent } = this.props;
    let frequency = e;
    document.getElementById(`frequency_error_${optInType}`) &&
      document
        .getElementById(`frequency_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`frequency_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`frequency_${optInType}`)
        .classList.remove('cw-error-focus');

    updateConsent(
      {
        ...elem,
        frequency: frequency.label
      },
      index
    );
  };

  render() {
    const {
      isValid,
      handleAccordionExpandCollapse,
      _handleLegalAgeConsentInputChange,
      _handleInputChange,
      removeAccordion,
      consentsTest,
      _openModal,
      nonRemovableConsents,
      _handleReset,
      elem,
      index,
      expandIndex,
      expand,
      isLegalAge,
      consentsUnavailable,
      countrySelected,
      isEdited,
      isEpsilonMarket,
      isMasterData,
      campaignType,
      campaignConfig,
      isUnbundledMarket,
      isPlatformAgnostic,
      isCombinedStatement,
      _handleLatastApprovedConsent,
      userrole,
      id,
      handleMultiChannelCheckboxClick,
      isOpenMultiChannelGuideModel,
      incentiveType
    } = this.props;
    const consentDefaultValue =
      consentsTest && consentsTest[index] && consentsTest[index].defaultCopy
        ? consentsTest[index].defaultCopy
        : '';
    const consentValue = elem && elem.defaultCopy ? elem.defaultCopy : '';
    const allowRoles = ["admin"]
    // make it disable for all counties except US as per 14262. remove top level true if more conditions are required
    const isImpliciteConsentRadioDisabled = () =>
      isEpsilonMarket
        ? false
        : !isEpsilonMarket &&
          !isUnbundledMarket &&
          allowRoles.includes(userrole)
        ? false
        : true ||
          (campaignType === 'Incentive' &&
            (incentiveNonMandatoryConsents.includes(elem.optInType) ||
              elem.optInType === consentTypes.termsAndConditions)) ||
          (isUnbundledMarket &&
            (elem.optInType === consentTypes.unileverOnlineOnlyConsent ||
              elem.optInType === consentTypes.unileverEmailOnlyConsent ||
              elem.optInType === consentTypes.unileverSMSOnlyConsent));

    return (
      <div className="accordion saas-form-field-accordion field" key={id}>
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip"
        />
        <ErrorIcon isValid={isValid} id={id} />
        <div className="card" style={{ border: 0, overflow: 'visible' }}>
          <div className="card-header  accordian-card-header">
            <span
              className="saas-accordion-heading cw-color--primary"
              style={{ fontWeight: '700' }}
            >
              {elem.label}
              {elem.optInType === 'contentInterestAreas' && (
                <img
                  src={NewStarIcon}
                  className="cia-new-icon"
                  alt="new star icon"
                  height={21}
                  width={21}
                />
              )}
            </span>

            <span
              type="button"
              className={`saas-accordion-arrow ${index}`}
              onClick={() => handleAccordionExpandCollapse(expandIndex)}
            >
              <i
                className={
                  expand === expandIndex
                    ? 'fas fa-chevron-down transformIcon-180'
                    : 'fas fa-chevron-down'
                }
              ></i>
            </span>
            {campaignType === 'Incentive' &&
            elem.optInType === 'termsAndConditions' ? null : elem.optInType !==
                'legalAgeConsent' &&
              elem.optInType !== 'privacyNotice' &&
              !nonRemovableConsents.includes(elem.optInType) ? (
              <span
                className={'saas-accordion-close'}
                onClick={() => {
                  removeAccordion(expandIndex, elem.optInType);
                }}
              ></span>
            ) : null}
          </div>
          <div
            className={expand === expandIndex ? 'collapse show' : 'collapse'}
          >
            {elem.optInType === 'brandSMSConsent' ||
            elem.optInType === 'unileverSMSConsent' ? (
              <div className="form-group">
                <Alert
                  alertType="saasInfoMessage"
                  withIcon
                  textMessage={
                    constant.SAAS_MESSAGES.mobileNumberCollectionMessage
                  }
                />
              </div>
            ) : null}
            {elem.optInType === consentTypes.multiChannelUnileverConsent ? (
              <div className="form-group">
                <Alert
                  alertType="saasInfoMessageWithKnowMore"
                  withIcon
                  textMessage={
                    constant.SAAS_MESSAGES.multiChannelToolTipMessage
                  }
                  openModal={isOpenMultiChannelGuideModel}
                  isKnowMore={true}
                  modelText={'consent guidelines'}
                />
              </div>
            ) : null}

            <ConsentStatement
              id={id}
              elem={elem}
              index={index}
              consentsUnavailable={consentsUnavailable}
              consentValue={consentValue}
              consentDefaultValue={consentDefaultValue}
              onConsentStatementChange={this.onConsentStatementChange}
              userrole={userrole}
              campaignConfig={campaignConfig}
              isEpsilonMarket={isEpsilonMarket}
              _handleReset={_handleReset}
              _handleLatastApprovedConsent={_handleLatastApprovedConsent}
              handleMultiChannelCheckboxClick={handleMultiChannelCheckboxClick}
              isOpenMultiChannelGuideModel={isOpenMultiChannelGuideModel}
            />

            {isMasterData &&
            campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT ? null : (
              <>
                {elem.optInType !== 'legalDisclaimer' &&
                elem.optInType !== 'privacyNotice' &&
                elem.optInType !== 'contentInterestAreas' ? (
                  <>
                    <div
                      className={'accordionDisplayOption ml-1 mt-20'}
                      key={`${elem.dataType}_${index}`}
                    >
                      <label>
                        Display Options
                        <span className="cw-icon cw-icon--help cw-icon--ml-5">
                          <i
                            className="fa fa-info-circle"
                            onClick={_openModal}
                          ></i>
                        </span>
                      </label>
                      <div className="form-check" style={{ width: '86%' }}>
                        <div className="cols-sm-4 mb-10">
                          <input
                            type="radio"
                            className="form-check-input"
                            id={`mandatoryRadio_${index}`}
                            name={`mandatoryRadio_${index}`}
                            value={
                              elem.consentType === 'mandatory' ? true : false
                            }
                            checked={
                              elem.consentType === 'mandatory' ||
                              elem.consentType === 'optional'
                                ? true
                                : false
                            }
                            onChange={e => this.handleMandatoryRadio(e, index)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`mandatoryRadio_${index}`}
                          >
                            Checkbox (Explicit consent)
                          </label>
                        </div>
                        {(isCombinedStatement &&
                          elem.optInType ===
                            'combinedUnileverAndBrandEmailConsent') ||
                        (isEpsilonMarket &&
                          campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT &&
                          (elem.optInType === 'unileverEmailConsent' ||
                            elem.optInType === 'brandEmailConsent' ||
                            elem.optInType === 'brandSMSConsent')) ? (
                          <div className="cols-sm-4 mb-10">
                            <input
                              type="radio"
                              className="form-check-input"
                              id={`statement_standalone_${index}`}
                              name={`statement_standalone_${index}`}
                              value={
                                elem.consentType === 'implicitStandalone'
                                  ? true
                                  : false
                              }
                              checked={
                                elem.consentType === 'implicitStandalone'
                                  ? true
                                  : false
                              }
                              onClick={e =>
                                this.handleStandaloneStatement(e, index)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`statement_standalone_${index}`}
                            >
                              Statement (Implicit consent - standalone)
                            </label>
                          </div>
                        ) : (
                          <div className="cols-sm-4 mb-10">
                            <input
                              type="radio"
                              className="form-check-input"
                              id={`statement_${index}`}
                              name={`statement_${index}`}
                              ref={this.statementRef}
                              value={
                                elem.consentType === 'implicit' ? true : false
                              }
                              checked={
                                elem.consentType === 'implicit' ? true : false
                              }
                              disabled={isImpliciteConsentRadioDisabled()}
                              onClick={e => this.handleStatement(e, index)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`statement_${index}`}
                            >
                              Statement (Implicit consent, text merged with
                              another consent)
                              {isImpliciteConsentRadioDisabled() ? (
                                <div className="email-sendafter-font">
                                  This option is unavailable
                                  <ReactTooltip
                                    id={'IncentiveStatementTooltip'}
                                    place="right"
                                    type="info"
                                    multiline={true}
                                    className="cw-tooltip custom-cw-toolip"
                                  />
                                  <span
                                    className="ml-10 cw-icon cw-icon--help"
                                    data-for="IncentiveStatementTooltip"
                                    data-tip={
                                      isUnbundledMarket
                                        ? 'Consent for your market cannot be an implied statement.'
                                        : campaignType === 'Incentive'
                                        ? elem.optInType ===
                                          consentTypes.termsAndConditions
                                          ? 'Incentive campaigns require consents to be explicit and hence, we do not allow consents to be made implied in case of forms with incentives.'
                                          : 'Incentive campaigns require consents to be explicit and optional and hence we do not allow consents to be made implied in case of forms with incentives.'
                                        : 'Multiple marketing consent statements can no longer be combined as combined statement translations are not available in DPO approved central consent repository.'
                                    }
                                  >
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </div>
                              ) : null}
                            </label>
                          </div>
                        )}
                        {// isEpsilonMarket && campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT &&
                        elem.optInType === 'legalAgeConsent' ? (
                          <div className="cols-sm-4 mb-10">
                            <input
                              type="radio"
                              className="form-check-input"
                              id={`statement_standalone_${index}`}
                              name={`statement_standalone_${index}`}
                              value={
                                elem.consentType === 'implicitStandalone'
                                  ? true
                                  : false
                              }
                              checked={
                                elem.consentType === 'implicitStandalone'
                                  ? true
                                  : false
                              }
                              onClick={e =>
                                this.handleStandaloneStatement(e, index)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`statement_standalone_${index}`}
                            >
                              Statement (Implicit consent - standalone)
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {elem.consentType !== 'implicit' &&
                    elem.consentType !== 'implicitStandalone' ? (
                      <div className="form-group form-group-field-width">
                        <div className="form-group d-flex ">
                          <label className="switch-saas">
                            <input
                              type="checkbox"
                              name={`mandateField_${index}`}
                              checked={
                                elem.consentType === 'mandatory' &&
                                elem.optInType !== 'unileverOnlineOnlyConsent'
                                  ? true
                                  : false
                              }
                              disabled={
                                (campaignType === 'Incentive' &&  incentiveType !== 'Product samples' &&  // Competitions
                                  incentiveNonMandatoryConsents.includes(
                                    elem.optInType
                                  )) ||
                                (isPlatformAgnostic &&
                                  elem.optInType ===
                                    consentTypes.legalAgeConsent)
                                  ? true
                                  : (campaignType === 'Incentive' &&
                                      elem.optInType ===
                                        'termsAndConditions') ||
                                    elem.optInType ===
                                      'unileverOnlineOnlyConsent' ||
                                    (isUnbundledMarket &&
                                      elem.optInType ===
                                        consentTypes.unileverOnlineOnlyConsent)
                                  ? true
                                  : campaignType === 'Incentive'
                                  ? false
                                  : isCombinedStatement
                                  ? false
                                  : campaignConfig.US_BRAND_SMS_CONSENT_ENABLE &&
                                    isEpsilonMarket &&
                                    elem.optInType ===
                                      consentTypes.brandSMSConsent
                                  ? false
                                  : campaignType !== 'Incentive' &&
                                    campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE &&
                                    elem.optInType ===
                                      consentTypes.multiChannelUnileverConsent
                                  ? true
                                  : nonRemovableConsents.includes(
                                      elem.optInType
                                    ) ||
                                    this.props.disableMandatoryToggle(
                                      elem.optInType
                                    )
                              }
                              onChange={e =>
                                this.handleMandatoryToggle(e, index)
                              }
                            />
                            <div className="slider-saas round"></div>
                          </label>
                          <label
                            className="ml-20"
                            htmlFor={`mandateField_${index}`}
                          >
                            {constant.MANDATORY_LABEL_MAP[elem.consentType] || ' Mandatory toggle' }
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                        {elem.consentType !== 'implicitStandalone' ? (
                          <div className="form-group">
                            <Alert
                              alertType="warningMessageWithIcon"
                              textMessage={constant.CONSENT_STATEMENT_MESSAGE}
                              showComponent={true}
                            />
                          </div>
                        ) : null}
                        {elem.optInType === 'legalAgeConsent' ? (
                          <div className="form-group">
                            <Alert
                              alertType="warningMessageWithIcon"
                              textMessage={constant.AGE_STATEMENT_WARNING}
                              showComponent={true}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                    {elem.consentType === 'mandatory' ? (
                      <>
                        <ReactTooltip
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                        />
                        <div
                          className="form-group"
                          style={{
                            margin: '0% 0 2% 1%'
                          }}
                        >
                          <label htmlFor={`errorMessage_${index}`}>
                            <span>Mandatory Error Message</span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Please specify the error message you would want users to see in case they forget to fill this field before proceeding
                                        to the next field or filled it in wrong format. Make sure to have this message in the language you selected for the form"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name={`errorMessage_${elem.optInType}`}
                            id={`errorMessage_${elem.optInType}`}
                            ref={`errorMessage_${elem.optInType}`}
                            aria-describedby={`errorMessage_${elem.optInType}`}
                            data-custom-error-type={`errorMessage_${elem.optInType}`}
                            value={elem.mandatoryMessage}
                            onChange={e =>
                              this._handleMandatoryError(
                                e,
                                index,
                                elem.optInType
                              )
                            }
                          />
                          <span
                            id={`consent_mandatoryMessage_${elem.optInType}`}
                            className="cw-error cw-hidden"
                            data-control="name"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter the error message
                          </span>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                {elem.optInType === 'legalAgeConsent' ? (
                  <div>
                    <div
                      className="form-group"
                      style={{ margin: '0% 0 2% 1%' }}
                    >
                      <label htmlFor="selectAge">
                        <span>Minimum legal age of consent</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="We will use this to ensure anyone who selects a lower age cannot sign-up"
                        >
                          <i className="fa fa-question-circle"></i>
                        </span>
                      </label>
                      <div id={`ageOfConsent`}>
                        <Select
                          className={'cw-select--custom cw-color--primary '}
                          name={`ageOfConsent`}
                          label={'ageOfConsent'}
                          placeholder={isLegalAge}
                          options={constant.LEGAL_AGE_VALUES.map(ele => {
                            return { label: ele, value: ele };
                          })}
                          onChange={e =>
                            _handleLegalAgeConsentInputChange(e, index, elem)
                          }
                          value={isLegalAge}
                        />
                      </div>
                      {/* 
                                    <span
                                      id="ageErrorMessage"
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please provide the age of consent
                                    </span> */}

                      <span
                        id={`error_legalAgeValidation_${elem.dataType}`}
                        className="cw-error cw-hidden"
                        data-control="name"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        You need to be atleast{' '}
                        {epsilonMarket(countrySelected) ? 13 : 16} years old to
                        participate
                      </span>
                    </div>
                  </div>
                ) : null}
                {elem.consentType !== 'implicit' &&
                elem.consentType !== 'implicitStandalone' &&
                elem.optInType === 'legalAgeConsent' ? (
                  <div className="form-group">
                    <Alert
                      alertType="warningMessageWithIcon"
                      textMessage={constant.AGE_STATEMENT_WARNING}
                      showComponent={true}
                    />
                  </div>
                ) : null}

                {elem.optInType === 'additionalSubscription' ||
                elem.optInType === 'additionalSubscription1' ? (
                  <>
                    <div
                      className="form-group"
                      style={{
                        margin: '0% 0 2% 1%'
                      }}
                    >
                      <label htmlFor="frequency">
                        <span>Frequency</span>
                      </label>
                      <Select
                        className={'cw-select--custom'}
                        label={'frequency'}
                        name={`frequency_${elem.optInType}`}
                        id={`frequency_${elem.optInType}`}
                        ref={`frequency_${elem.optInType}`}
                        aria-describedby={`frequency_${elem.optInType}`}
                        placeholder={'Select option'}
                        options={this.frequency}
                        onChange={e =>
                          this._handleFrequency(e, index, elem.optInType)
                        }
                        value={
                          elem.frequency
                            ? {
                                label: elem.frequency,
                                value: elem.frequency.toLowerCase()
                              }
                            : null
                        }
                      />
                      <span
                        id={`frequency_error_${elem.optInType}`}
                        className="cw-error cw-hidden"
                        data-control={`frequency_${elem.optInType}`}
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the frequency
                      </span>
                    </div>
                    <ReactTooltip
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip"
                    />
                    <div
                      className="form-group"
                      style={{
                        margin: '0% 0 2% 1%'
                      }}
                    >
                      <label htmlFor={`serviceId_${index}`}>
                        <span>Service ID (Optional)</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Please add the service ID for this opt-in if you already have it. For e.g. If you are migrating your form from one platform to another, you many already have a service ID for your additional consent. If you do not have it, we will request it from Data Bureau"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <input
                        disabled={isEdited}
                        type="text"
                        className="form-control"
                        name={`serviceId`}
                        id={`serviceId_${elem.optInType}`}
                        ref={`serviceId_${elem.optInType}`}
                        aria-describedby={`serviceId_${elem.optInType}`}
                        maxLength={25}
                        value={elem.serviceId || ''}
                        onChange={e => _handleInputChange(e, index)}
                        onBlur={this._handleInputOnBlur}
                      />
                    </div>
                  </>
                ) : null}
                {elem.optInType === 'brandEmailConsent' && !isEpsilonMarket ? (
                  <div
                    style={{
                      margin: '2% 0 2% 1%'
                    }}
                  >
                    <div className="mb-10">
                      <p>
                        <b>Please provide details for email communications</b>
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="emailSenderName">
                        <span>Email Senders Name</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="emailSenderName"
                        id="emailSenderName"
                        ref="emailSenderName"
                        maxLength="50"
                        aria-describedby="emailSenderName"
                        data-required="true"
                        value={elem.emailSenderName || ''}
                        onChange={e => _handleInputChange(e, index)}
                      />

                      <span
                        className="cw-error cw-hidden"
                        data-control="emailSenderName"
                        data-custom-msg="emailSenderName"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the email sender's name
                      </span>
                    </div>

                    <div className="form-group">
                      <label htmlFor={`replyToEmailName_${index}`}>
                        <span>Reply to Email Name (Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="replyToEmailName"
                        id="replyToEmailName"
                        ref="replyToEmailName"
                        maxLength="50"
                        aria-describedby="replyToEmailName"
                        value={elem.replyToEmailName || ''}
                        onChange={e => _handleInputChange(e, index)}
                        onBlur={this._handleInputOnBlur}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="replyToEmailAdderess">
                        <span>Reply to Email Address (Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="replyToEmailAdderess"
                        id="replyToEmailAdderess"
                        ref="replyToEmailAdderess"
                        aria-describedby="replyToEmailAdderess"
                        data-custom-error="true"
                        data-custom-error-type="email"
                        value={elem.replyToEmailAdderess || ''}
                        onChange={e => _handleInputChange(e, index)}
                        onBlur={this._handleInputOnBlur}
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-custom-msg="replyToEmailAdderess"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter valid email.
                      </span>
                    </div>
                  </div>
                ) : null}
              </>
            )}
            {elem.optInType === 'contentInterestAreas' ? (
              <div
                style={{
                  margin: '2% 0 2% 1%'
                }}
              >
                <div className="mb-10">
                  <Alert
                    alertType="saasInfoMessageWithIcon"
                    textMessage={constant.CIA_FIELD_INFO_MESSAGE}
                  />
                  <div className="form-group">
                    <label htmlFor="consumerFacingLabel">
                      <span>Consumer facing label</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="consumerFacingLabel"
                      id="consumerFacingLabel"
                      ref="consumerFacingLabel"
                      aria-describedby="consumerFacingLabel"
                      data-custom-error="true"
                      data-custom-error-type="email"
                      value="Are there any of these areas you would like to hear more on from our brands"
                      disabled
                    />
                    <span
                      className="cw-error cw-hidden"
                      data-custom-msg="consumerFacingLabel"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter valid label.
                    </span>
                  </div>
                  <div className="form-group">
                    <label>
                      <span>Field values</span>
                    </label>
                    <ul className="cia-list">
                      <li>Parenting &amp; Family</li>
                      <li>Planet, Nature and Society</li>
                      <li>Home &amp; Hygiene</li>
                      <li>Food &amp; Recipes</li>
                      <li>Beauty &amp; Styling</li>
                      <li>Wellness, Nutrition and Active Lifestyle</li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
