import axios from 'axios';
import constant from '../../constants';
import _ from 'lodash';
import {
  GET_SMS_CAMPAIGN_DETAILS,
  UDPATE_SMS_CAMPAIGN_DETAILS,
  GET_FETCH_SMS_CAMPAIGN_DETAILS,
  FETCH_SMS_CAMPAIGN_DETAILS_FOR_COUNTRY,
  SUBMIT_SMS_CAMPAIGN_DETAILS,
  SMS_ASYNC_IN_PROGRESS,
  SMS_SYSTEM_ERROR,
  VALIDATE_SMS_CAMPAIGN_NAME,
  RESET_SMS_CAMPAIGN_DETAILS,
  GET_SMS_OVERVIEW_DETAIL,
  SUBMIT_SMS_CAMPAIGN_TYPE
} from '../../actionTypes';
import { setNotificationMessage } from 'actions/dashboard';

export const updateSmsCampaignDetails = updateSmsCampaignDetails => ({
  type: UDPATE_SMS_CAMPAIGN_DETAILS,
  payload: updateSmsCampaignDetails
});

export const getSmsCampaignBrandsInformation = (
  country,
  brand,
  language = [],
  callback
) => {
  return function(dispatch) {
    axios
      .get(
        constant.communications.FETCH_SMS_BRAND_DETAIL + '?country=' + country
      )
      .then(response => {
        let brandsData = response.data;
        let sortedBrandsData = _.orderBy(brandsData, ['name'], ['asc']);
        let smsBrandCampaignDetails = sortedBrandsData.filter(
          ele => ele.brand === brand
        );

        let optOutText = '';
        let languageList = smsBrandCampaignDetails?.[0]?.language || [];

        languageList.forEach(item => {
          if (item.languageCode === language[0]?.code) {
            optOutText = item.optOutText;
          }
        });

        dispatch({
          type: FETCH_SMS_CAMPAIGN_DETAILS_FOR_COUNTRY,
          smsBrandCampaignDetails,
          optOutText
        });
        if (callback) {
          callback(null, optOutText);
        }
      });
  };
};

export const retrieveSmsCampaignInformation = uid => {
  return function(dispatch) {
    dispatch({
      type: SMS_ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let url = constant.communications.POST_SMS_BRAND_DETAIL;
    axios.get(url + '/' + uid).then(response => {
      let smsBrandDetails = response.data;
      let country = smsBrandDetails && smsBrandDetails.country;
      let brand = smsBrandDetails && smsBrandDetails.brand;
      let language = smsBrandDetails && smsBrandDetails.languageDetails;
      dispatch({
        type: GET_FETCH_SMS_CAMPAIGN_DETAILS,
        smsBrandDetails
      });
      dispatch(getSmsCampaignBrandsInformation(country, brand, language));
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: false
      });
    });
  };
};

export const getSavedSmsCampaignDetails = uid => {
  return function(dispatch) {
    let url = constant.communications.GET_SAVED_SMS_DETAIL;
    axios.get(url + '/' + uid).then(response => {
      let smsCampaignDetails = response && response.data;
      let smsDetails =
        response &&
        response.data &&
        response.data.communicationDetail &&
        response.data.communicationDetail.smsDetails;
      dispatch({
        type: GET_SMS_CAMPAIGN_DETAILS,
        smsCampaignDetails,
        smsDetails
      });
    });
  };
};

export const submitSmsCampaignDetails = (
  uid,
  name,
  smsOverview,
  targetAudience,
  expectedVolume,
  smsDetails,
  dpcFlag,
  dpcPraReferenceNo,
  additionalSMSRequired,
  saveAsDraft
) => {
  return function(dispatch) {
    dispatch({
      type: SMS_ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let sendSMSCampaignDetails = {
      uid: uid,
      name: name,
      smsOverview: smsOverview,
      targetAudience: targetAudience,
      expectedVolume: expectedVolume,
      smsDetails: smsDetails,
      dpcFlag: dpcFlag,
      dpcPraReferenceNo: dpcPraReferenceNo,
      additionalSMSRequired: additionalSMSRequired,
      saveAsDraft: saveAsDraft
    };
    let postUrl = constant.communications.POST_SMS_CAMPAIGN_DETAILS;

    axios
      .post(postUrl, sendSMSCampaignDetails)
      .then(response => {
        dispatch({
          type: SUBMIT_SMS_CAMPAIGN_DETAILS,
          sendSMSCampaignDetails,
          response
        });
      })
      .catch(err => {
        dispatch({
          type: SMS_ASYNC_IN_PROGRESS,
          isLoading: false
        });
        dispatch({
          type: SMS_SYSTEM_ERROR,
          err
        });
        return false;
      });
  };
};

export function validateSmsCampignName(smsCampaignName, uid) {
  return function(dispatch) {
    return axios
      .get(
        constant.communications.VALIDATE_SMS_NAME +
          '?name=' +
          encodeURIComponent(smsCampaignName) +
          '&uid=' +
          uid
      )
      .then(response => {
        dispatch({
          type: VALIDATE_SMS_CAMPAIGN_NAME,
          isSMSNameValid: response.data && response.data.valid
        });
        return response.data && response.data.valid;
      })
      .catch(err => {
        return false;
      });
  };
}

export const submitSmsCampaignDetailsV2 = (
  data,
  callback,
  { isSaveAsDraft } = {}
) => {
  return function(dispatch) {
    dispatch({
      type: SMS_ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let postUrl = constant.communications.POST_SMS_CAMPAIGN_DETAILS;
    if (data.uid) {
      axios
        .post(postUrl, data)
        .then(response => {
          console.log('response: ', response);
          dispatch({
            type: SUBMIT_SMS_CAMPAIGN_DETAILS,
            data,
            response
          });
          if (isSaveAsDraft) {
            console.log(response);
            const campaignName = _.get(response, 'data.name', 'Untitled');
            const uid = _.get(response, 'data.uid');
            dispatch(
              setNotificationMessage(
                constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT,
                `${constant.SMS_BASE_ROUTE}/${uid}`,
                'success',
                campaignName,
                'emailCampaignDraft'
              )
            );
          }
          if (callback) {
            callback(null, response.data);
          }
        })
        .catch(err => {
          dispatch({
            type: SMS_ASYNC_IN_PROGRESS,
            isLoading: false
          });
          dispatch({
            type: SMS_SYSTEM_ERROR,
            err
          });
          return false;
        });
    } else {
      // no uid found so it must be from sms type page
      dispatch({
        type: SUBMIT_SMS_CAMPAIGN_TYPE,
        data
      });
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: false
      });
      callback(null, data);
    }
  };
};

export const updateSmsCampaignStatus = (
  data,
  callback,
  { disableLoader } = {}
) => {
  return function(dispatch) {
    if (!disableLoader) {
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: true
      });
    }
    let postUrl = constant.communications.UPDATE_SMS_CAMPAIGN_STATUS;
    axios
      .post(postUrl, data)
      .then(response => {
        dispatch({
          type: SUBMIT_SMS_CAMPAIGN_DETAILS,
          data,
          response
        });
        let smsDetailsOverview =
          response && response.data && response.data.data;
        dispatch({
          type: GET_SMS_OVERVIEW_DETAIL,
          smsDetailsOverview
        });
        if (callback) {
          callback(null, response && response.data && response.data.data);
        }
      })
      .catch(err => {
        if (!disableLoader) {
          dispatch({
            type: SMS_ASYNC_IN_PROGRESS,
            isLoading: false
          });
        }
        dispatch({
          type: SMS_SYSTEM_ERROR,
          err
        });
        return false;
      });
  };
};

export const smsUploadFileWithStatus = (
  data,
  jsonData,
  callback,
  { disableLoader } = {}
) => {
  return function(dispatch) {
    if (!disableLoader) {
      dispatch({
        type: SMS_ASYNC_IN_PROGRESS,
        isLoading: true
      });
    }
    let postUrl =
      constant.communications.UPDATE_FILE_UPLOAD_AND_SMS_CAMPAIGN_STATUS;
    axios
      .post(postUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: jsonData
      })
      .then(response => {
        dispatch({
          type: SUBMIT_SMS_CAMPAIGN_DETAILS,
          data,
          response
        });
        let smsDetailsOverview =
          response && response.data && response.data.data;
        dispatch({
          type: GET_SMS_OVERVIEW_DETAIL,
          smsDetailsOverview
        });
        if (callback) {
          callback(null, response && response.data && response.data.data);
        }
      })
      .catch(err => {
        if (!disableLoader) {
          dispatch({
            type: SMS_ASYNC_IN_PROGRESS,
            isLoading: false
          });
        }
        dispatch({
          type: SMS_SYSTEM_ERROR,
          err
        });
        return false;
      });
  };
};

export const resetSmsCampaignDetails = () => {
  return {
    type: RESET_SMS_CAMPAIGN_DETAILS
  };
};
