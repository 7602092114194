// Authorization HOC
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ls from 'local-storage';
import Loader from '../components/Loader';
import {
  fetchUserData,
  getCampaignDetails,
  getEmailCampaignDetails,
  getSmsCampaignDetails
} from '../actions/authorization';
import {
  checkApproverCampaignEmailAccess,
  checkCustomRulesList,
  checkAccessToEmailBrefingV2,
  checkAccessToSMS
} from '../selectors';
import { MsalContext } from '@azure/msal-react';
import { msalConfig } from 'authConfig';
import { PageRestrict } from './PageRestrict';
import { InteractionStatus } from '@azure/msal-browser';
import { getLoggedInUser } from 'utilities/util';
import moment from 'moment';
import constants from 'constants/config';

class WithAuthorization extends React.Component {
  constructor(props) {
    super(props);

    this.state = { role: null, emailId: null, showLoader: true };
  }

  static contextType = MsalContext;
  componentDidMount() {
    //let userDetails = ls.get('userDetails') || {};
    let userDetails = getLoggedInUser(this.context.instance);
    // if (Object.entries(userDetails).length === 0 && userDetails.constructor === Object) {
    //  userDetails['Profile-EmailAddress'] = 'binay.kumar@publicissapient.com';
    // }
    const lastLoginTime = moment(localStorage.getItem('lastLogin'));
    if (lastLoginTime.isValid()) {
      const currentTime = moment();
      if (
        lastLoginTime
          .add(
            constants.USER_FORCELOGOUT_TIMEOUT.amount,
            constants.USER_FORCELOGOUT_TIMEOUT.unit
          )
          .isSameOrBefore(currentTime)
      ) {
        localStorage.removeItem('lastLogin');
        this.context.instance.logoutRedirect({
          account: userDetails,
          postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
        });
      }
    } else {
      // add a last login entry.
      const timeStampCurrent = moment();
      localStorage.setItem('lastLogin', timeStampCurrent.format());
      // set timer to force logout after duration set in config
      setTimeout(() => {
        localStorage.removeItem('lastLogin');
        this.context.instance.logoutRedirect({
          account: userDetails,
          postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
        });
      }, moment.duration(constants.USER_FORCELOGOUT_TIMEOUT.amount, constants.USER_FORCELOGOUT_TIMEOUT.unit).asMilliseconds());
    }

    this.props
      .fetchUserData(userDetails)
      .then(resp => {
        if (
          this.props.match.params.id &&
          (window.location.pathname.includes('/new-email-campaign') ||
            window.location.pathname.includes('/emailbriefing'))
        ) {
          this.props.getEmailCampaignDetails(this.props.match.params.id);
        } else if (
          this.props.match.params.id &&
          !window.location.pathname.includes('/communications')
        ) {
          this.props.getCampaignDetails(this.props.match.params.id);
        } else if (
          this.props.match.params.id &&
          window.location.pathname.includes('/communications')
        ) {
          this.props.getSmsCampaignDetails(this.props.match.params.id);
        }
        if (resp) {
          this.setState({
            role: resp
              ? resp.group
              : '',
            emailId: resp
              ? resp.emailId
              : ''
          });
        }
      })
      .catch(error => {
        console.log('error => ', error);
        this.setState({showLoader: false});
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevState.role !== this.state.role) && this.state.role) {
      this.setState({showLoader: false});
    }
    
    let userDetails = getLoggedInUser(this.context.instance);
    const lastLoginTime = moment(localStorage.getItem('lastLogin'));
    if (lastLoginTime.isValid()) {
      const currentTime = moment();
      if (
        lastLoginTime
          .add(
            constants.USER_FORCELOGOUT_TIMEOUT.amount,
            constants.USER_FORCELOGOUT_TIMEOUT.unit
          )
          .isSameOrBefore(currentTime)
      ) {
        localStorage.removeItem('lastLogin');
        this.context.instance.logoutRedirect({
          account: userDetails,
          postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
        });
      }
    }
  }

  render() {
    let role = this.state.role ? this.state.role : '';
    let WrappedComponent = this.props.componentName;
    let { campaignAccessData, isEditedCampaign } = this.props;

    //approverEditCampaign
    let campaignStatusData = {
      isEdited: isEditedCampaign,
      status: campaignAccessData && campaignAccessData.status,
      emailId: this.props.authorizedUserData.emailId,
      nmiAssignedToRequestor:
        campaignAccessData && campaignAccessData.nmiAssignedToRequestor
    };
    let hasAccessToEmailBriefing = checkAccessToEmailBrefingV2(
      this.props.authorizedUserData,
      campaignAccessData,
      this.props.pageName
    );

    let hasAccessToSMS = checkAccessToSMS(
      this.props.authorizedUserData,
      campaignAccessData,
      this.props.pageName
    );
    /* Checking isEdited or not */
    let isEditedclickevent =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isEdited;

    let accountsList = this.context.instance.getAllAccounts();

    if (this.state.showLoader) {
      return <Loader />;
    } else {
      if (
        this.props.asyncInProgress ||
        this.props.emailAsyncInProgress ||
        this.props.smsAsyncInProgress
      ) {
        return <Loader />;
      } else {
        if (this.context.instance.inProgress === InteractionStatus.Login) {
          return (
            <Redirect
              to={{
                pathname: '/login-redirect'
              }}
            />
          );
        }
        if (this.context.instance.inProgress === InteractionStatus.Logout) {
          return (
            <Redirect
              to={{
                pathname: '/login-redirect'
              }}
            />
          );
        }
        if (
          this.context.instance.inProgress === InteractionStatus.HandleRedirect
        ) {
          return (
            <Redirect
              to={{
                pathname: '/login-redirect'
              }}
            />
          );
        }
        if (!Object.keys(this.props.authorizedUserData).length) {
          //window.location.href = '/login';
          // store location
          localStorage.setItem('refererUrl', this.props.location.pathname);
          return (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          );
        } else if (
          this.props.userNotAuthorized ||
          !this.props.allowedRoles.includes(role) ||
          (role !== 'requestor' &&
            this.props.allowedRoles.includes(role) &&
            this.props.authorizedUserData &&
            !this.props.authorizedUserData.loginAccess) ||
          (this.props.match.params.id &&
            !!campaignAccessData &&
            !window.location.pathname.includes('/communications') &&
            !window.location.pathname.includes(
              '/new-email-campaign-emailtype'
            ) &&
            !(campaignAccessData && campaignAccessData.isCampaignValid)) ||
          hasAccessToEmailBriefing ||
          checkApproverCampaignEmailAccess(
            this.props.authorizedUserData,
            role,
            window
          )
        ) {
          return (
            <div className="col-sm-12">
              <PageRestrict
                uid={
                  !hasAccessToEmailBriefing &&
                  campaignAccessData &&
                  campaignAccessData.isCampaignValid
                    ? this.props.match.params.id
                    : ''
                }
              />
            </div>
          );
        } else if (
          this.props.userNotAuthorized ||
          !this.props.allowedRoles.includes(role) ||
          (role !== 'requestor' &&
            this.props.allowedRoles.includes(role) &&
            this.props.authorizedUserData &&
            !this.props.authorizedUserData.loginAccess) ||
          (this.props.match.params.id &&
            !!campaignAccessData &&
            window.location.pathname.includes('/communications') &&
            !(campaignAccessData && campaignAccessData.isCampaignValid)) ||
          hasAccessToSMS
        ) {
          return (
            <div className="col-sm-12">
              <PageRestrict
                uid={
                  !hasAccessToSMS &&
                  campaignAccessData &&
                  campaignAccessData.isCampaignValid
                    ? this.props.match.params.id
                    : ''
                }
              />
            </div>
          );
        } else if (
          window.location.pathname.includes('/communications') &&
          window.location.pathname.includes('/execution') &&
          isEditedclickevent === undefined &&
          this.props.customRules &&
          !!(campaignAccessData && campaignAccessData.status) &&
          checkCustomRulesList(
            this.props.customRules,
            campaignStatusData,
            this.props.pageName,
            this.props.authorizedUserData.group
          )
        ) {
          this.props.history.push(
            '/campaign-wizard/communications/' + this.props.match.params.id
          );
          return (
            <div className="col-sm-12">
              <div className="cw-section">
                {/* <h2 className="cw-heading--secondary mb-30">401</h2> */}
                <p className="alert alert-danger mb-40">
                  <strong>
                    {' '}
                    You campaign is completed. Please go to your{' '}
                    <a href="/campaign-wizard/dashboard">home</a> page.{' '}
                  </strong>
                </p>
              </div>
            </div>
          );
        } else if (
          (!window.location.pathname.includes('/new-email-campaign') ||
            window.location.pathname.includes('/emailbriefing')) &&
          !window.location.pathname.includes(
            '/emailbriefing/email-collection'
          ) &&
          !window.location.pathname.includes('/campaign/') &&
          isEditedclickevent === undefined &&
          this.props.customRules &&
          !!(campaignAccessData && campaignAccessData.status) &&
          checkCustomRulesList(
            this.props.customRules,
            campaignStatusData,
            this.props.pageName,
            this.props.authorizedUserData.group
          )
        ) {
          this.props.history.push(
            '/campaign-wizard/emailbriefing/' + this.props.match.params.id
          );
          return (
            <div className="col-sm-12">
              <div className="cw-section">
                {/* <h2 className="cw-heading--secondary mb-30">401</h2> */}
                <p className="alert alert-danger mb-40">
                  <strong>
                    {' '}
                    You campaign is completed. Please go to your{' '}
                    <a href="/campaign-wizard/dashboard">home</a> page.{' '}
                  </strong>
                </p>
              </div>
            </div>
          );
        } else if (
          !window.location.pathname.includes('/communications') &&
          !window.location.pathname.includes('/new-email-campaign') &&
          !window.location.pathname.includes('/emailbriefing') &&
          !window.location.pathname.includes('/email-dashboard') &&
          !window.location.pathname.match(
            /\/campaign\/[a-zA-Z0-9]*\/[a-zA-Z0-9-]+/
          ) &&
          window.location.pathname.match('/campaign/') &&
          isEditedclickevent === undefined &&
          this.props.customRules &&
          !!(campaignAccessData && campaignAccessData.status) &&
          checkCustomRulesList(
            this.props.customRules,
            campaignStatusData,
            this.props.pageName,
            this.props.authorizedUserData.group
          )
        ) {
          this.props.history.push(
            '/campaign-wizard/campaign/' + this.props.match.params.id
          );
          return (
            <div className="col-sm-12">
              <div className="cw-section">
                {/* <h2 className="cw-heading--secondary mb-30">401</h2> */}
                <p className="alert alert-danger mb-40">
                  <strong>
                    {' '}
                    You campaign is completed. Please go to your{' '}
                    <a href="/campaign-wizard/dashboard">home</a> page.{' '}
                  </strong>
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <WrappedComponent
              {...this.props}
              userrole={
                this.props.authorizedUserData &&
                this.props.authorizedUserData.group
              }
              userName={
                this.props.authorizedUserData &&
                this.props.authorizedUserData.name
              }
              userEmail={
                this.props.authorizedUserData &&
                this.props.authorizedUserData.emailId
              }
              uid={
                campaignAccessData && campaignAccessData.isCampaignValid
                  ? this.props.match.params.id
                  : ''
              }
            />
          );
        }
      }
    }
  }
}

const mapStateToProps = state => ({
  authorizedUserData: state.authorized.authorizedUserData,
  asyncInProgress: state.authorized.asyncInProgress,
  userAuthorized: state.authorized.asyncInProgress,
  userNotAuthorized: state.authorized.asyncInProgress,
  campaignAccessData: state.authorized.campaignAccessData,
  asyncGetStatus: state.authorized.asyncGetStatus,
  isEditedCampaign: state.overview.editCampaign,
  emailAsyncInProgress: state.authorized.emailAsyncInProgress,
  smsAsyncInProgress: state.authorized.smsAsyncInProgress
});

export default connect(mapStateToProps, {
  fetchUserData,
  getCampaignDetails,
  getEmailCampaignDetails,
  getSmsCampaignDetails
})(WithAuthorization);
