import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import updateLastScreen from './utilities/interceptors';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { logoutUserState } from 'actions/authorization';
import { getLoggedInUser } from 'utilities/util';
import moment from 'moment';
import constants from 'constants/config';
import EventEmitter from 'events';

const store = configureStore();
const eventEmitter = new EventEmitter();

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

/**
 * To set an active account after the user signs in, register an event and listen for LOGIN_SUCCESS and LOGOUT_SUCCESS. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    const setActiveAccountPromise = msalInstance.setActiveAccount(account);
    //localStorage.setItem('userDetails', account)
    // redirect user
    const refererUrl = localStorage.getItem('refererUrl');
    // save current time
    const lastLoginTime = moment()
    console.log("set last login index: ", lastLoginTime);
    localStorage.setItem('lastLogin', lastLoginTime.format());
    // set timer to force logout after duration set in config
    setTimeout(() => {
      localStorage.removeItem('lastLogin')
      // msalInstance.logoutRedirect({
      //   account: account,
      //   postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
      // });
      console.log("settime index execute");
    }, moment.duration(constants.USER_FORCELOGOUT_TIMEOUT.amount, constants.USER_FORCELOGOUT_TIMEOUT.unit).asMilliseconds() )
    if (refererUrl) {
      localStorage.removeItem('refererUrl');
      eventEmitter.emit('redirectUser', {
        pathName: refererUrl,
        userDetails: account
      });
    } else {
      eventEmitter.emit('redirectUser', {
        pathName: '/campaign-wizard',
        userDetails: account
      });
    }
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    // track logout
    const t = localStorage.getItem('cwtoken');
    eventEmitter.emit('logoutTrack', {
      e: 'logoutIndex',
      t: t
    });
  }
});

window.msalInstance = msalInstance;
updateLastScreen();

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App eventEmitter={eventEmitter} />
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
