import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';
import QualifyingQuestionToggle from '../QualifyingQuestionToggle/QualifyingQuestionToggle';
import constant from '../../../constants';
import Alert from '../../Alert';
import constants from 'constants/config';
export default function IncentiveField({
  errors,
  elem,
  isValid,
  index,
  isProductSample,
  isEdited,
  removeAccordion,
  qualifyingQuestionCount,
  qualifyingQuestionHandlerIncentive,
  validateIncentiveField,
  updateIncentiveField,
  setIncetiveErrors,
  expand,
  handleAccordionExpandCollapse,
  isMasterData,
  campaignConfig
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const _closeModal = () => setIsModalOpen(false);
  const _openModal = () => setIsModalOpen(true);

  const onChangeRadio = (e, index) => {
    let radioValue = e.target.value;
    elem.questionType = radioValue;
    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const onLabelChange = (e, index, elem) => {
    let fieldLabel = e.target.value;
    elem.fieldLabel = fieldLabel;
    setIncetiveErrors(({ incentiveErrors }) => ({
      incentiveErrors: {
        ...incentiveErrors,
        [`field_label_${index}`]: fieldLabel.trim().length ? false : true
      }
    }));
    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const onFileSizeChange = (e, index, elem) => {
    let fileSize = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (fileSize > parseInt(constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE)) {
      elem.maxFileSize = fileSize;

      setIncetiveErrors(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`max_file_size_${index}`]: true
        }
      }));
      validateIncentiveField(elem, index);
      updateIncentiveField(elem, index);
    } else if (fileSize !== '0' && (fileSize === '' || regex.test(fileSize))) {
      elem.maxFileSize = fileSize;

      setIncetiveErrors(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`fileSize_${index}`]: false,
          [`max_file_size_${index}`]: false
        }
      }));
      validateIncentiveField(elem, index);
      updateIncentiveField(elem, index);
    }
  };

  const _addAnswer = (e, index) => {
    let isValid = false;
    let answerArr = elem && elem.answers ? elem.answers : [];
    let answerNew = {
      label: '',
      value: `prize-${answerArr.length}`
    };
    answerArr.forEach((answer, ansIndex) => {
      if (answer && answer.label.trim().length === 0) {
        document.getElementById(`answer_Error_Message_${index}_${ansIndex}`) &&
          document
            .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.add('cw-error-focus');

        isValid = true;
      }
    });

    if (isValid) {
      return;
    }
    if (elem && elem.answers && elem.answers.length === 12) {
      document.getElementById(`answer_Error_Message_${index}`) &&
        document
          .getElementById(`answer_Error_Message_${index}`)
          .classList.remove('cw-hidden');
    } else {
      elem && elem.answers && elem.answers.push(answerNew);

      elem.answers.forEach((ele, anindex) => {
        ele.label = elem.answers[anindex].label;
        ele.value = `prize-${anindex}`;
      });
      validateIncentiveField(elem, index);
      updateIncentiveField(elem, index);
    }
  };

  const handleAnswerLabel = (e, ansIndex) => {
    elem.answers[ansIndex].label = e.target.value;
    document.getElementById(`answer_Error_Message_${index}_${ansIndex}`) &&
      document
        .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
        .classList.remove('cw-error-focus');

    // validateIncentiveField(elem, index);
    updateIncentiveField({ ...elem }, index);
  };

  const _deleteAnswer = (index, answerIndex) => {
    // let updatedAnswers =
    elem && elem.answers && elem.answers.splice(answerIndex, 1);

    if (elem && elem.answers && elem.answers.length !== 12) {
      document.getElementById(`answer_Error_Message_${index}`) &&
        document
          .getElementById(`answer_Error_Message_${index}`)
          .classList.add('cw-hidden');
    }

    elem.answers.forEach((ele, anindex) => {
      ele.label = elem.answers[anindex].label;
      ele.value = `prize-${anindex}`;
    });

    elem.answers.forEach((answer, ansIndex) => {
      if (answer && answer.label.trim().length === 0) {
        document.getElementById(`answer_Error_Message_${index}_${ansIndex}`) &&
          document
            .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.add('cw-error-focus');
      } else {
        document
          .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
          .classList.add('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.remove('cw-error-focus');
      }
    });

    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const _handleToolTip = (e, index) => {
    let enteredText = e.target.value;
    elem.toolTip = enteredText;
    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const _mandatoryQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    elem.mandatoryToggle = isChecked;
    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const _handleMandatoryMessage = (e, index, elem) => {
    let enteredText = e.target.value;

    setIncetiveErrors(({ incentiveErrors }) => ({
      incentiveErrors: {
        ...incentiveErrors,
        [`errorMessage_${index}`]: enteredText.trim().length ? false : true
      }
    }));
    elem.mandatoryMessage = elem.mandatoryToggle ? enteredText : '';
    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const _handleValidationMessage = (e, index, elem) => {
    let enteredText = e.target.value;
    setIncetiveErrors(({ incentiveErrors }) => ({
      incentiveErrors: {
        ...incentiveErrors,
        [`validationMessage_${index}`]: enteredText.trim().length ? false : true
      }
    }));
    elem.validationMessage = enteredText;

    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  const _handleFileUploadMessage = (e, index, elem) => {
    let enteredText = e.target.value;

    setIncetiveErrors(({ incentiveErrors }) => ({
      incentiveErrors: {
        ...incentiveErrors,
        [`fileUploadMessage_${index}`]: enteredText.trim().length ? false : true
      }
    }));

    elem.failedUploadMessage = enteredText;

    updateIncentiveField(elem, index);
    validateIncentiveField(elem, index);
  };

  const _handleImageOnchange = (e, index, imgIndex) => {
    elem.values[imgIndex].isChecked = e.target.checked;
    validateIncentiveField(elem, index);
    updateIncentiveField(elem, index);
  };

  return (
    <div
      id={`accordion_${elem.value}_${index + 1}`}
      className="accordion saas-form-field-accordion field"
      key={`${elem.label}_${index + 1}`}
    >
      <ReactTooltip
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={_closeModal}
        className="cw-modal cw-modal--branddetail"
        contentLabel="Can't find your brand?"
        style={{
          overlay: {
            backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button className="cw-modal--close" title="Close" onClick={_closeModal}>
          <i className="fas fa-times"></i>
        </button>

        <p className="mb-10">
          {`We recommend you use this field to let your users know the valid file
          types and size upfront, based on your selection. For example, add a
          message "You can only upload jpg and png files of upto ${constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE}KB`}
          .
        </p>
      </Modal>

      <ErrorIcon isValid={isValid} />
      <div className="card" style={{ border: 0, overflow: 'visible' }}>
        <div className="card-header">
          <span
            className="saas-accordion-heading cw-color--primary"
            style={{ fontWeight: '700' }}
          >
            {elem.label}
          </span>
          <span
            type="button"
            className={
              isMasterData &&
              campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT &&
              elem.dataType !== 'samplePrizeSelection'
                ? 'd-none'
                : `saas-accordion-arrow ${index}`
            }
            onClick={() => handleAccordionExpandCollapse(index)}
          >
            <i
              className={
                expand === index
                  ? 'fas fa-chevron-down transformIcon-180'
                  : 'fas fa-chevron-down'
              }
            ></i>
          </span>
          {isProductSample &&
          elem.dataType === 'samplePrizeSelection' ? null : (
            <span
              className={'saas-accordion-close'}
              onClick={() => {
                removeAccordion(index);
              }}
            ></span>
          )}
        </div>
        <div className={expand === index ? 'collapse show' : 'collapse'}>
          <ReactTooltip
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
          <>
            {elem.label !== 'Competition Details' ? (
              <>
                <div
                  className="form-group form-group-field-width"
                  key={`${elem.dataType}_${index}`}
                >
                  {elem.dataType === 'samplePrizeSelection' && isEdited && (
                    <div className="form-group">
                      <Alert
                        alertType="warningMessageWithIcon"
                        textMessage={
                          constant.SAAS_MESSAGES.sampleDetailsWarningMsg
                        }
                        showComponent="true"
                      />
                    </div>
                  )}
                  <label htmlFor={`entry_code_field_label_${index}`}>
                    <span>Field Label</span>
                    {elem.dataType === 'entryCode' ? (
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="Use to enter the unique entry code which can be found near the barcode."
                        currentitem="false"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    ) : null}
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      errors[`field_label_${index}`] ? 'cw-error-focus' : ''
                    }`}
                    name={`field_label_${index}`}
                    id={`field_label_${index}`}
                    aria-describedby={`field_label_${index}`}
                    data-required="true"
                    defaultValue={elem.fieldLabel ? elem.fieldLabel : ''}
                    onChange={e => onLabelChange(e, index, elem)}
                  />
                  <span
                    id={`labelError_${index}`}
                    className={`cw-error ${
                      errors[`field_label_${index}`] ? '' : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the field label
                  </span>
                </div>
              </>
            ) : null}
            {elem.dataType === 'fileUpload' ? (
              <>
                <div className="form-group form-group-field-width">
                  <label>
                    <span>
                      {`Max file size (Upto ${constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE} Kb allowed)`}
                    </span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip={`This is the max size of each file that consumers will be allowed to upload. If they try to add a bigger file, they will see an error`}
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`fileSize_${index}`] ? 'cw-error-focus' : ''
                    }`}
                    id={`fileSize_${index}`}
                    aria-describedby={`fileSize_${index}`}
                    data-required="true"
                    value={elem.maxFileSize ? elem.maxFileSize : ''}
                    onChange={e => onFileSizeChange(e, index, elem)}
                  />
                  <span
                    id={`file_size_${index}`}
                    className={`cw-error ${
                      errors[`fileSize_${index}`] ? '' : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please specify the file size
                  </span>

                  <span
                    id={`max_file_size_${index}`}
                    className={`cw-error ${
                      errors[`max_file_size_${index}`] ? '' : 'cw-hidden'
                    }`}
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    {`File size cannot exceed ${constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE} kb`}
                  </span>
                </div>
                <div className="form-group form-group-field-width">
                  <label>
                    <span>Allowed file types (optional)</span>
                  </label>
                  {elem.values.map((el, imageIndex) => {
                    return (
                      <div className="form-group">
                        <div className="row mb-1">
                          <div className="col-9">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={el.isChecked}
                                onChange={e =>
                                  _handleImageOnchange(e, index, imageIndex)
                                }
                                name={`file_upload_${index}_${imageIndex}`}
                                id={`file_upload_${index}_${imageIndex}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`file_upload_${index}_${imageIndex}`}
                              >
                                {el.key}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            {elem.dataType === 'samplePrizeSelection' ? (
              <>
                <div className="form-group-field-width position-relative">
                  <label>
                    <span>Sample Details</span>
                  </label>
                </div>
                {elem.answers &&
                  elem.answers.map((answer, answerIndex) => {
                    return (
                      <>
                        <div
                          className="form-group-field-width position-relative"
                          key={`${answer.value}`}
                        >
                          <div className="form-group">
                            {answerIndex >= 1 ? (
                              <span
                                className="saas-accordion-close incentive"
                                data-index={index}
                                onClick={e => _deleteAnswer(index, answerIndex)}
                              ></span>
                            ) : null}

                            <input
                              type="text"
                              placeholder="Please enter the sample details"
                              className="form-control mb-20"
                              name={`answerTranslatedLabel_${answerIndex}`}
                              id={`answerTranslatedLabel_${index}_${answerIndex}`}
                              aria-describedby={`answerTranslatedLabel_${answerIndex}`}
                              data-required="true"
                              value={answer.label}
                              onChange={e => handleAnswerLabel(e, answerIndex)}
                            />
                            <span
                              className="cw-error cw-hidden"
                              id={`answer_Error_Message_${index}_${answerIndex}`}
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter the sample details or remove the
                              empty fields. At-least one sample should be added
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                <button
                  onClick={e => _addAnswer(e, index)}
                  className="btn btn-outline-primary"
                  style={{ margin: '1% 0 1% 1%' }}
                >
                  Add another sample
                </button>
                <span
                  className="form-group form-group-field-width cw-error cw-hidden"
                  id={`answer_Error_Message_${index}`}
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  Maximum 12 samples can be added
                </span>
                {elem.answers && elem.answers.length > 1 ? (
                  <>
                    <div
                      className="form-group form-group-field-width"
                      key={`${elem.dataType}_${index}`}
                    >
                      <label htmlFor={`entry_code_field_label_${index}`}>
                        <span>Sample Selection </span>
                      </label>
                      <div className="form-check mb-10">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="singleSampleFlag_yes"
                            name="singleSampleFlag"
                            value={'Single choice'}
                            checked={
                              elem && elem.questionType === 'Single choice'
                                ? true
                                : false
                            }
                            onClick={e => onChangeRadio(e, index)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="singleSampleFlag_yes"
                          >
                            <p className="form-check-label d-block mr-2">
                              Allow users to select only one sample
                            </p>
                          </label>
                        </div>
                      </div>
                      <div className="form-check mb-10">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="singleSampleFlag_no"
                            name="multiSampleFlag"
                            value={'Multiple choice'}
                            checked={
                              elem && elem.questionType === 'Multiple choice'
                                ? true
                                : false
                            }
                            onClick={e => onChangeRadio(e, index)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="singleSampleFlag_no"
                          >
                            <p className="form-check-label d-block mb-5">
                              Allow users to select more than one sample
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
            {elem.label !== 'Competition Details' ? (
              <>
                <div
                  className={
                    isMasterData &&
                    campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT &&
                    elem.dataType === 'samplePrizeSelection'
                      ? 'd-none'
                      : `form-group form-group-field-width`
                  }
                >
                  <label htmlFor={`toolTipText_${index}`}>
                    <span> Tooltip/Help-Text (optional)</span>
                    {elem.dataType === 'fileUpload' ? (
                      <span class="ml-10">
                        <a href="#modal" onClick={_openModal}>
                          Important Note!
                        </a>
                      </span>
                    ) : null}
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the tooltip message you would want users to see in case they need some additional information while filling up the form. Make sure to have this message in the language you selected for the form."
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name={`toolTipText_${index}`}
                    id={`toolTipText_${index}`}
                    aria-describedby={`toolTipText_${index}`}
                    data-required="true"
                    value={elem && elem.toolTip ? elem.toolTip : ''}
                    onChange={e => _handleToolTip(e, index)}
                  />
                </div>

                <div
                  className={
                    isMasterData &&
                    campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT &&
                    elem.dataType === 'samplePrizeSelection'
                      ? 'd-none'
                      : 'form-group d-flex form-group-field-width'
                  }
                >
                  <label className="switch-saas">
                    <input
                      type="checkbox"
                      name="quesMandatory"
                      checked={elem && elem.mandatoryToggle ? true : false}
                      onChange={e => _mandatoryQuestionHandler(e, index)}
                    />
                    <div className="slider-saas round"></div>
                  </label>
                  <label className=" ml-20" htmlFor="quesMandatory">
                    {/* Make this field mandatory */}
                    {elem && elem.mandatoryToggle  ? constants.MANDATORY_LABEL_MAP['mandatory'] : constants.MANDATORY_LABEL_MAP['optional']} 
                  </label>
                </div>
              </>
            ) : null}
            {elem && elem.mandatoryToggle ? (
              <>
                <ReactTooltip
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip"
                />
                <div className="form-group form-group-field-width">
                  <label htmlFor={`errorMessage_${index}`}>
                    <span>Mandatory Field Error Message</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`errorMessage_${index}`] ? 'cw-error-focus' : ''
                    }`}
                    name={`errorMessage_${index}`}
                    id={`errorMessage_${index}`}
                    aria-describedby={`errorMessage_${index}`}
                    data-required="true"
                    value={
                      elem && elem.mandatoryMessage ? elem.mandatoryMessage : ''
                    }
                    onChange={e => _handleMandatoryMessage(e, index, elem)}
                  />
                  <span
                    className={`cw-error ${
                      errors[`errorMessage_${index}`] ? '' : 'cw-hidden'
                    }`}
                    id={`mandatory_Error_Message_${index}`}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the error message
                  </span>
                </div>
              </>
            ) : null}
            {elem.dataType === 'entryCode' ? (
              <QualifyingQuestionToggle
                disabled={
                  !elem.qualifyingToggle && qualifyingQuestionCount >= 5
                }
                checked={elem && elem.qualifyingToggle ? true : false}
                qualifyingQuestionHandler={qualifyingQuestionHandlerIncentive}
                index={index}
                elem={elem}
              />
            ) : null}
            {elem.dataType === 'fileUpload' ? (
              <>
                <QualifyingQuestionToggle
                  disabled={
                    !elem.qualifyingToggle && qualifyingQuestionCount >= 5
                  }
                  checked={elem && elem.qualifyingToggle ? true : false}
                  qualifyingQuestionHandler={qualifyingQuestionHandlerIncentive}
                  index={index}
                  elem={elem}
                />
                <div className="form-group form-group-field-width">
                  <label htmlFor={`validationMessage_${index}`}>
                    <span>Format Validation Error Message</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please enter the error message to be displayed if a user tries to upload a file with incorrect file format or size"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`validationMessage_${index}`]
                        ? 'cw-error-focus'
                        : ''
                    }`}
                    name={`validationMessage_${index}`}
                    id={`validationMessage_${index}`}
                    aria-describedby={`validationMessage_${index}`}
                    data-required="true"
                    value={
                      elem && elem.validationMessage
                        ? elem.validationMessage
                        : ''
                    }
                    onChange={e => _handleValidationMessage(e, index, elem)}
                  />
                  <span
                    className={`cw-error ${
                      errors[`validationMessage_${index}`] ? '' : 'cw-hidden'
                    }`}
                    id={`validation_Error_Message_${index}`}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the format validation error message
                  </span>
                </div>
                <div className="form-group form-group-field-width">
                  <label htmlFor={`fileUploadMessage_${index}`}>
                    <span>Failed Upload Error Message </span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the error message you would want the users to see in case the file upload has failed due to a technical issue. We recommend the message to be in the language you selected for your form."
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors[`fileUploadMessage_${index}`]
                        ? 'cw-error-focus'
                        : ''
                    }`}
                    name={`fileUploadMessage_${index}`}
                    id={`fileUploadMessage_${index}`}
                    aria-describedby={`fileUploadMessage_${index}`}
                    data-required="true"
                    value={
                      elem && elem.failedUploadMessage
                        ? elem.failedUploadMessage
                        : ''
                    }
                    onChange={e => _handleFileUploadMessage(e, index, elem)}
                  />
                  <span
                    className={`cw-error ${
                      errors[`fileUploadMessage_${index}`] ? '' : 'cw-hidden'
                    }`}
                    id={`file_Upload_Error_Message_${index}`}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the error message for a failed upload
                  </span>
                </div>
              </>
            ) : null}
          </>
        </div>
      </div>
    </div>
  );
}
